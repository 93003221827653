import React, { useEffect, useState } from 'react';
import PaymentForm from '../citations/PaymentForm';
import { BackArrow } from '../../utility/BackArrow';
import { Link } from 'react-router-dom';
import "../../../styles.css";
import './profile.css';
import { fetchAllData, postData } from '../../utility/fetcher';

export const PermitBrowser = () => {
    // Get user info
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const [purchasePermitOpen, setPurchasePermitOpen] = useState(false);
    const [permitUni, setPermitUni] = useState("");
    const [tempUniData, setTempUniData] = useState("");
    const [permitType, setPermitType] = useState("");
    const [vehicles, setVehicles] = useState([]);
    const [purchaseButton, setPurchaseButton] = useState("Browse");
    const [purchasePrompt, setPurchasePrompt] = useState("Need a new permit?");
    const [purchaseReady, setPurchaseReady] = useState(false);
    const [allOrgData, setAllOrgData] = useState([]);
    const userVehicles = userData.settings.vehicles

    const storeData = async () => {
        try {
            const path = { "allOrgData": "/general/organizations" }; //TODO this needs to be the updates /orgsWithPublicPermits function
            const response = await fetchAllData(path);
            setAllOrgData(response.allOrgData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        storeData();
    }, []);

    let filteredUniData = []
    for (let i in allOrgData) {
        const currentUni = allOrgData[i]
        const currentPermits = currentUni.permitTypes
        let publicPermits = []
        for (let j in currentPermits) {
            if (currentPermits[j].public) {
                publicPermits.push(currentPermits[j])
            }
        }

        if (publicPermits.length > 0) {
            filteredUniData.push(currentUni)
        }
    }

    // Handlers
    const [editedData, setEditedData] = useState({});
    const handleEdit = (e) => {
        const { name, value } = e.target;

        if (name === "organization") {
            setPermitUni(value === "" ? "" : value);
            setPermitType("");
            setVehicles([]);
            const chosenUni = filteredUniData.filter((uni) => uni.orgName === value);
            setTempUniData(chosenUni[0]);
        } else if (name === "permit") {
            setVehicles([]);
            setPermitType(value);
            setPurchaseReady(true);
        } else if (name === "vehicles") {
            setVehicles([...vehicles, value]);
        }
        setEditedData({ ...editedData, [name]: value });
    };

    const handleCloseClick = () => {
        setPermitUni("");
        setPermitType("");
        setVehicles([]);
        setPurchaseReady(false);
        purchaseButton === "Browse" ? setPurchaseButton("Close") : setPurchaseButton("Browse");
        purchasePrompt === "Need a new permit?" ? setPurchasePrompt("") : setPurchasePrompt("Need a new permit?");
        setPurchasePermitOpen(!purchasePermitOpen)
    }

    const pushToDatabase = async (objBuild) => {
        userData.permits.push(objBuild);
        objBuild.vehicles = vehicles;
        console.log(objBuild)

        try {
            console.log("Data to save:", userData);
            const path = "/general/spotUsers";
            const response = await postData(path, userData);
            console.log(response.message);
        } catch (error) {
            console.error("Error saving data:", error);
        }

        sessionStorage.setItem("userData", JSON.stringify(userData));
    }

    const allCurrentPermits = tempUniData !== "" && permitUni !== "" ? tempUniData.permitTypes.filter(permit => permit.public) : [];

    return (
        <div className='fcc p-6'>
            <BackArrow path="/profile" />
            <div className="fcc py-4 w-full tile transition-all ease-in-out duration-500 dark:border-gray-600">
                <div className={`overflow-hidden transition-all ease-in-out duration-500 ${purchasePermitOpen ? "max-h-fit" : "max-h-0"}`}>
                    <div className='fcc gap-4 p-2 text-spotGray dark:text-gray-300'>
                        <h1 className='font-bold text-2xl text-spotGray dark:text-gray-300'>Select a Permit</h1>
                        <div className='frc gap-2 dark:text-gray-300'>
                            <label htmlFor="organization">Organization:</label>
                            <select
                                name='organization'
                                value={permitUni}
                                onChange={handleEdit}
                                className="w-48 inline-block pl-1 text-sm font-normal dark:bg-transparent h-6 border border-gray-200 dark:border-gray-600 rounded-md"
                            >
                                <option value="" className='w-fit text-spotGray'>Select an Organization</option>
                                {filteredUniData.map((uni, index) => (
                                    <option key={`organization-${index}`} value={uni.orgName}>{uni.orgName}</option>
                                ))}
                            </select>
                        </div>
                        <div className={`overflow-hidden transition-all ease-in-out duration-500 ${permitUni !== "" ? "max-h-fit" : "max-h-0"}`}>
                            <label htmlFor="permit">Permit Type: </label>
                            <select
                                name='permit'
                                value={permitType}
                                onChange={handleEdit}
                                className="w-48 inline-block pl-1 text-sm font-normal dark:bg-transparent h-6 border border-gray-200 dark:border-gray-600 rounded-md"
                            >
                                <option value="" className='w-fit text-spotGray'>Select a Permit</option>
                                {permitUni !== "" ? allCurrentPermits.map((permit, index) => {
                                    return (
                                        <option key={`permit-${index}`} value={permit.description} className='w-fit text-spotGray'>{permit.permitType} - {permit.description}</option>
                                    )
                                }) : null}
                            </select>
                        </div>
                        <div className={`fcc gap-2 w-80 overflow-hidden transition-all ease-in-out duration-500 ${permitType !== "" ? "max-h-fit" : "max-h-0"}`}>
                            {permitType !== "" ?
                                <div className="fcc">
                                    <label htmlFor="permit">Active Vehicles: </label>
                                    <div className="frc gap-4">
                                        {userVehicles.length === 0 ?
                                            <Link to={"/vehicles"} className="text-sm underline text-spotGray dark:text-gray-300">Add a vehicle</Link> :
                                            userVehicles.map((vehicle, index) => (
                                                <label key={`permit-${index}`} className="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        name="vehicles"
                                                        value={vehicle.licensePlate}
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;
                                                            if (checked) {
                                                                setVehicles([...vehicles, value]);
                                                            } else {
                                                                setVehicles(vehicles.filter((plate) => plate !== value));
                                                            }
                                                        }}
                                                        disabled={!vehicles.includes(vehicle.licensePlate) && tempUniData.permitTypes.filter(permit => permit.description === permitType)[0].platesPerPermit <= vehicles.length}
                                                        className="form-checkbox h-4 w-4 text-spotGray dark:text-gray-300 transition duration-150 ease-in-out accent-spotYellow"
                                                    />
                                                    <span className="ml-2 text-spotGray dark:text-gray-300">{vehicle.make} {vehicle.model}</span>
                                                </label>
                                            ))}
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className={`fcc gap-2 w-80 overflow-hidden transition-all ease-in-out duration-500 ${vehicles.length !== 0 ? "max-h-fit" : "max-h-0"}`}>
                            {vehicles.length !== 0 ?
                                <PaymentForm
                                    amount={tempUniData.permitTypes.filter(permit => permit.description === permitType)[0].price}
                                    type={"permit"}
                                    dbFunction={pushToDatabase}
                                    permitDescription={permitType}
                                    permitUni={permitUni}
                                    permitType={tempUniData.permitTypes.filter(permit => permit.description === permitType)[0].permitType}
                                    onBack={handleCloseClick}
                                />
                                : null}
                        </div>
                    </div>
                </div>
                <div className={`frc gap-4 text-spotGray dark:text-gray-300`}>
                    <div>{purchasePrompt}</div>
                    {purchaseButton !== "Browse" ? null :
                        <div className={`shadow-md rounded-lg transition-all duration-200 ease-in-out text-spotGray text-sm font-bold p-2 px-4 cursor-pointer ${purchaseReady && purchaseButton === "Purchase" ? "yellow-gradient" : "light-gray-gradient"}`} onClick={handleCloseClick}>
                            Browse
                        </div>
                    }
                </div>
            </div>
        </div >
    );
}