import React from "react";
import NavBar from "../../NavBar/NavBar";
import Map from "./Map";
import "./Map.css";

function MapView() {
    return (
        <div style={{ overflow: "hidden" }}>
            <Map />
        </div>
    );
}

export default MapView;
