import React, { useState } from "react";
import PaymentForm from "./PaymentForm";
import ApppealForm from "./AppealForm";
import { BackArrow } from "../../utility/BackArrow";
import { postData } from "../../utility/fetcher";

//TODOL Make sure that the new api stuff works well

export const SingleCitation = () => {
    const [action, setAction] = useState(null);
    const [message, setMessage] = useState('');
    const currentCitation = JSON.parse(sessionStorage.getItem("currentCitation"));
    const amount = currentCitation.fee;
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const allCitations = userData.citations

    const individualParameter = (title, data) => {
        if (title === "Fee") {
            const formattedFee = amount.includes("$") ? amount : `$${parseFloat(amount).toFixed(2)}`;
            data = formattedFee;
        }

        return (
            <div className="frc gap-1">
                <p className="text-spotGray dark:text-gray-300 font-bold font-xl">{title}: </p>
                <p className="text-spotGray dark:text-gray-300 text-sm text-left">{data}</p>
            </div>
        );
    }

    const saveToCitationDatabase = async (confirmationNumber) => {
        const path = `/general/spotUsers?${userData.email}`;
        currentCitation.appeals[0] = {
            "appealDate": currentCitation.appeals[0].appealDate,
            "reason": currentCitation.appeals[0].reason,
            "replyDate": new Date().toISOString(),
            "replyNotes": "Paid",
            "requestNotes": currentCitation.appeals[0].requestNotes,
            "reviewedBy": "N/A",
            "status": "Paid"
        }

        const citationBuild = {
            "appeals": currentCitation.appeals,
            "citationID": currentCitation.citationID,
            "fee": amount,
            "location": currentCitation.location,
            "lot": currentCitation.lot,
            "paid": true,
            "spot": currentCitation.spot,
            "timeWritten": currentCitation.timeWritten,
            "type": currentCitation.type,
            "confirmationNumber": confirmationNumber,
            "licensePlate": currentCitation.licensePlate,
            "datePaid": new Date().toISOString(),
        }

        let newAllCitations = [];
        if (allCitations) {
            const filteredCitations = allCitations.filter(citation => citation.citationID !== currentCitation.citationID);
            newAllCitations = [...filteredCitations, citationBuild];
            sessionStorage.setItem("allCitations", JSON.stringify(newAllCitations));
        }

        userData.citations = newAllCitations;

        const response = await postData(path, userData);
        console.log(response.message);
    };

    const saveToAppealDatabase = async (citationData) => {
        const appealBuild = {
            "appealDate": new Date().toISOString(),
            "reason": citationData.reason,
            "replyDate": "",
            "replyNotes": "",
            "requestNotes": citationData.additionalNotes,
            "reviewedBy": "",
            "status": "pending",
        }

        userData.citations.forEach(citation => {
            if (citation.citationID === currentCitation.citationID) {
                console.log(citation)
                citation.appeals.push(appealBuild);
            }
        });

        try {
            const path = `/general/spotUsers?${userData.email}`;
            (async () => {
                const response = await postData(path, userData);
                console.log(response.message);
                setAction(null)
                setMessage('Appeal submitted successfully!');
            })();
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const handleActionClick = (action) => {
        setAction(action);
    }

    const handleBackClick = () => {
        setAction(null);
    }

    const appeals = currentCitation.appeals;
    let status = "";
    if (appeals.length === 0) {
        status = "No Appeals Pending";
    } else if (currentCitation.paid === true) {
        status = "PAID";
    } else {
        for (let i in appeals) {
            if (appeals[i].status === "pending") {
                status = "Appeal Pending"
                break;
            } else if (appeals[i].status === "Denied") {
                status = "Appeal Denied"
                break;
            } else if (appeals[i].status === "Approved") {
                status = "Appeal Approved"
                break;
            }
        }
    }

    return (
        <div className="fcc pb-24">
            <BackArrow path="/citations" />
            <div className="fcc w-screen p-4 gap-4 pb-8">
                <h1 className="text-3xl text-spotGray dark:text-gray-300">Citation Info</h1>
                <div className="flex flex-col justify-start items-start p-4 rounded-lg shadow-md w-full h-fit py-4 border border-gray-100 dark:border-gray-600 dark:text-gray-300">
                    <h2 className="text-2xl text-spotGray dark:text-gray-300 font-medium w-full text-center pb-2">#{currentCitation.citationID}</h2>
                    {individualParameter("Location", currentCitation.location)}
                    {individualParameter("License Plate", currentCitation.licensePlate)}
                    <div className="frc gap-4">
                        {individualParameter("Spot", `${currentCitation.lot}-${currentCitation.spot}`)}
                        {individualParameter("Fee", currentCitation.fee)}
                    </div>
                    {individualParameter("Time Written", new Date(currentCitation.timeWritten).toLocaleString())}
                    {individualParameter("Violation Type", currentCitation.type)}
                    {individualParameter("Status", status)}
                    {currentCitation.Paid ? individualParameter("Confirmation Number", currentCitation.confirmationNumber) : null}
                </div>
                {currentCitation.paid ? null :
                    <div className={`w-full h-fit py-4  ${action !== null ? "fcc border border-gray-100 rounded-lg shadow-md" : "flex flex-row justify-center transition-all duration-500 ease-in-out"}`}>
                        {message !== '' ? <p className="text-center text-spotGray dark:text-gray-300">{message}</p> : status === "Appeal Approved" ? null :
                            <div className={`${action !== null || action === "done" ? "hidden" : "w-full gap-6 frc"} transition-all duration-500 ease-in-out`}>
                                {status === "No Appeals Pending" &&
                                    <button type="submit" onClick={() => handleActionClick("appeal")} className={`w-20 h-8 self-center rounded-md light-gray-gradient rubik-medium shadow-md dark:text-spotGray`}>
                                        Appeal
                                    </button>
                                }
                                <button type="submit" onClick={() => handleActionClick("pay")} className={`w-20 h-8 self-center rounded-md yellow-gradient rubik-medium shadow-md dark:text-spotGray`}>
                                    Pay
                                </button>
                            </div>
                        }
                        {action === null ? null : action === "pay" ? <PaymentForm amount={amount} type={"citation"} dbFunction={saveToCitationDatabase} onBack={handleBackClick} description={`Citation #${currentCitation.CitationID}`} /> : <ApppealForm amount={amount} dbFunction={saveToAppealDatabase} onBack={handleBackClick} description={`Citation #${currentCitation.citationID}`} />}
                    </div>
                }
            </div>
        </div>
    )
}