// src/MetaTags.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MetaTags = () => {
    const [color, setColor] = useState('#ffffff'); // Default color
    const [profileColor, setProfileColor] = useState('#323232')

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleDarkModeChange = (e) => {
            if (e.matches) {
                setColor('#323232'); // Dark mode color
                setProfileColor('#C5C5C5')
            } else {
                setColor('white'); // Light mode color
                setProfileColor('#323232')
            }
        };

        // Set initial color based on current theme
        if (darkModeMediaQuery.matches) {
            setColor('#323232'); // Dark mode color
            setProfileColor('#C5C5C5')
        } else {
            setColor('white'); // Light mode color
            setProfileColor('#323232')
        }

        // Add event listener
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

        // Clean up event listener
        return () => {
            darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
    }, []);

    const location = useLocation();

    useEffect(() => {
        const updateMetaTags = () => {
            const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
            const appleStatusBarMetaTag = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');

            if (window.location.pathname === '/profile') {
                if (themeColorMetaTag) {
                    themeColorMetaTag.setAttribute('content', profileColor); // Black for profile page
                }
                if (appleStatusBarMetaTag) {
                    appleStatusBarMetaTag.setAttribute('content', 'black-translucent');
                }
            } else {
                if (themeColorMetaTag) {
                    themeColorMetaTag.setAttribute('content', color); // Default color
                }
                if (appleStatusBarMetaTag) {
                    appleStatusBarMetaTag.setAttribute('content', 'default');
                }
            }
        };

        updateMetaTags();
    }, [location]);

    return null;
};

export default MetaTags;