import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "./components/login/Login";
import { ResetPassword } from "./components/login/ResetPassword";
import { SignUp } from "./components/login/SignUp";
import { ContactUs } from "./components/login/ContactUs";
import { Announcements } from "./components/pages/announcements/Announcements";
import { Citations } from "./components/pages/citations/Citations";
import { SingleCitation } from "./components/pages/citations/SingleCitation";
import { ActivePermits } from "./components/pages/profile/ActivePermits";
import { ExpiredPermits } from "./components/pages/profile/ExpiredPermits";
import { PersonalInfo } from "./components/pages/profile/PersonalInfo";
import { FAQ } from "./components/pages/profile/FAQ";
import NavBar from "./components/NavBar/NavBar";
import LotView from "./components/pages/lotView/lotView/LotView";
import MapView from "./components/pages/mapView/MapView";
import Profile from "./components/pages/profile/Profile";
import MetaTags from "./components/utility/MetaTags";
import Cookies from "js-cookie";
import "./styles.css";
import { Vehicles } from "./components/pages/profile/Vehicles";
import { ConfirmEmail } from "./components/login/ConfirmEmail";
import { SingleVehicle } from "./components/pages/profile/SingleVehicle";
import ErrorBoundary from "./components/utility/ErrorBoundary";
import ErrorScreen from "./components/utility/ErrorScreen";
import { logout } from "./components/utility/logout";
import SoloMapView from "./components/pages/SoloMapView";
import { VisitorCitation } from "./components/pages/VisitorCitation";

export default function App() {
    // Hard coded login info
    const user = "Ryan";
    const defaultSchool = "Brigham Young University"
    const jsCookie = Cookies.get("accessToken");
    sessionStorage.setItem("user", user);
    sessionStorage.setItem("school", defaultSchool);

    // Prevents pinch zoom on mobile. No need to touch
    useEffect(() => {
        const disablePinchZoom = (event) => {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        };
        document.addEventListener("touchmove", disablePinchZoom, { passive: false });
        return () => {
            document.removeEventListener("touchmove", disablePinchZoom);
        };
    }, []);

    const currentPath = window.location.pathname;

    // If the user is not authenticated, redirect to login page
    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
    } else {
        console.log("Authenticated:", false);
        logout();

        const allowedPaths = ["/login", "/contact-us", "/reset-password", "/reset-password", "/signup", "/confirm-email", "/soloMap", "/solomap", "/classDemo", "/visitorCitation"];
        if (!allowedPaths.includes(currentPath)) {
            window.location.href = "/login";
        }
    }

    return (
        <div>
            <ErrorBoundary fallback={<ErrorScreen />}>
                <Router>
                    {jsCookie === undefined || currentPath.includes("soloMap") || currentPath.includes("classDemo") || currentPath === "/visitorCitation" ? null : <NavBar />}
                    <MetaTags />
                    <Routes>
                        <Route path="/" element={<LotView school={defaultSchool} />} />
                        {/* <Route path="/" element={<TestMapview />} /> */}
                        {/* <Route path="/" element={<PaymentForm />} /> */}
                        <Route path="/profile" element={<Profile school={defaultSchool} user={user} />} />
                        <Route path="/personal-information" element={<PersonalInfo />} />
                        {/* <Route path="/payment-information" element={<PaymentInfo />} /> */}
                        <Route path="/vehicles" element={<Vehicles />} />
                        <Route path="/vehicles/:vehicle" element={<SingleVehicle />} />
                        <Route path="/active-permits" element={<ActivePermits />} />
                        <Route path="/expired-permits" element={<ExpiredPermits />} />
                        <Route path="/faq" element={<FAQ />} />
                        {/* <Route path="/help-center" element={<HelpCenter />} /> */}
                        <Route path="/mapView" element={<MapView />} />
                        <Route path="/announcements" element={<Announcements />} />
                        <Route path="/citations" element={<Citations />} />
                        <Route path="/visitorCitation" element={<VisitorCitation />} />
                        <Route path="/citations/:location/:licensePlate" element={<SingleCitation />} />
                        {/* Sign up stuff */}
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/confirm-email" element={<ConfirmEmail />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        {/* Testing */}
                        <Route path="/soloMap" element={<SoloMapView />} />
                    </Routes>
                </Router>
            </ErrorBoundary>
        </div>
    );
}