import queryString from 'query-string';

// Function to detect if the user is on an Apple device
const isAppleDevice = () => {
    return /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
};

export const openMapWithDirections = (latitude, longitude) => {
    const destinationCoords = `${latitude},${longitude}`;

    const googleMapsParams = {
        appUrl: `comgooglemaps://?saddr=Current+Location&daddr=${destinationCoords}`,
        webUrl: 'https://www.google.com/maps/dir/',
        query: {
            api: 1,
            destination: destinationCoords
        }
    };

    const appleMapsParams = {
        appUrl: `maps://?saddr=Current+Location&daddr=${destinationCoords}`,
        webUrl: 'https://maps.apple.com/',
        query: {
            saddr: 'Current Location',
            daddr: destinationCoords
        }
    };

    const params = isAppleDevice() ? appleMapsParams : googleMapsParams;

    // Try to open the native app URL first
    const mapUrl = params.appUrl;

    const win = window.open(mapUrl, '_blank');

    // If the app is not available, fall back to the web version
    setTimeout(() => {
        if (!win || win.closed || typeof win.closed === 'undefined') {
            const fallbackUrl = params.webUrl + '?' + queryString.stringify(params.query);
            window.open(fallbackUrl, '_blank');
        }
    }, 1000); // 1 second delay to check if the native app opened
};