import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TestMap from "./SoloMap";
import { fetchAllData } from "../utility/fetcher";
import "./mapView/Map.css";

function SoloMapView() {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const orgID = query.get("orgID");

    const [allOrgs, setAllOrgs] = useState([""]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [button, setButton] = useState("Submit");

    const getOrgs = async () => {
        const data = await fetchAllData({ "allOrgs": "/public/getOrganizations" });
        setAllOrgs(data.allOrgs.organizations);
    }

    const getOrgID = async (orgName) => {
        setButton("Loading...");
        const data = await fetchAllData({ "orgID": `/public/getOrgByName?orgName=${orgName}` });
        const orgID = data.orgID.orgID;
        window.location.href = `/soloMap?orgID=${orgID}`;
    }

    const handleSelect = (event) => {
        const orgName = event.target.value;
        setSelectedOrg(orgName);
    };

    useEffect(() => {
        getOrgs();
    }, []);

    const Selector = () => {
        return (
            <div className="fcc gap-2 text-lg h-screen w-screen font-rubik">
                <label htmlFor="organization">Select Organization:</label>
                <select id="organization" className="bg-gray-100 p-2 rounded-md w-48" value={selectedOrg} onChange={handleSelect}>
                    <option value="">Select</option>
                    {allOrgs.map(org => (
                        <option key={org} value={org}>{org}</option>
                    ))}
                </select>
                <button className={`${selectedOrg === null ? "disabled bg-gray-200" : "yellow-gradient"} text-spotGray p-2 rounded-md mt-4 w-auto px-2`} onClick={() => getOrgID(selectedOrg)}>{button}</button>
            </div>
        )
    }

    return (
        <div style={{ overflow: "hidden" }}>
            {orgID === null ? <Selector /> : <TestMap orgID={orgID} />}
        </div>
    );
}

export default SoloMapView;
