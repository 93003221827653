import React, { useState, useEffect } from 'react';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Toaster, toast } from 'sonner';
import { FaUserGraduate } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import UserPool from "../../utility/UserPool.js";
import './profile.css';
import "../../../styles.css";
import { BackArrow } from '../../utility/BackArrow.js';
import { logout } from '../../utility/logout.js';
import ErrorBoundary from '../../utility/ErrorBoundary.js';
import { Button } from '../../utility/Button.js';
import { postData } from '../../utility/fetcher.js';

export const PersonalInfo = () => {
    // Get user info
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const userSettings = userData.settings;
    const personalSettings = userSettings.personalInfo;
    const [saveText, setSaveText] = useState("Save");
    const [logoutText, setLogoutText] = useState("Log Out");
    const [editedData, setEditedData] = useState({});

    // Set initial data
    var initialData = {
        email: userData.email,
        firstName: personalSettings.firstName,
        lastName: personalSettings.lastName,
    };

    useEffect(() => {
        setEditedData(initialData);
    }, []);

    // Handlers
    const handleEdit = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    // Check if the email is in email format
    const validateEmail = (inputEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(inputEmail);
    };

    // If it's not in email format then throw an error toast
    const handleEmailBlur = () => {
        const inputEmail = editedData["email"].trim();
        if (validateEmail(inputEmail)) {
            return true;
        } else {
            toast.error("Invalid email address");
        }
    };

    // Fix label to be more readable
    const labelFixer = (label) => {
        return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    };

    const handleSave = async () => {
        // Things that can't be empty when submitted
        const notEmptyList = ["firstName", "lastName", "email"];

        // Check if any of the not empty fields are empty
        let bool = true;
        while (bool === true) {
            for (let i in notEmptyList) {
                if (editedData[notEmptyList[i]] === "") {
                    toast.error(labelFixer(notEmptyList[i]) + " field cannot be empty.");
                    return;
                }
            }
            bool = false;
        }

        setSaveText("Saving...");

        // Update user attributes in AWS Cognito
        const cognitoUser = UserPool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    console.log(err);
                    return;
                }

                const attributes = [
                    new CognitoUserAttribute({ Name: "email", Value: editedData["email"] }),
                ];

                cognitoUser.updateAttributes(attributes, (err, result) => {
                    if (err) {
                        console.error("Failed to update attributes:", err);
                        toast.error("Failed to save settings.");
                    } else {
                        console.log("Successfully updated attributes:", result);
                        sessionStorage.setItem("userSettings", JSON.stringify(editedData));
                        toast.success("Settings saved successfully.");
                    }
                });
            });
        } else {
            toast.error("User not authenticated.");
        }

        userData.settings.personalInfo.firstName = editedData["firstName"];
        userData.settings.personalInfo.lastName = editedData["lastName"];

        try {
            console.log("Data to save:", userData);
            const path = "/general/spotUsers";
            const response = await postData(path, userData);
            console.log(response.message);
            sessionStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("fName", editedData["firstName"]);
            localStorage.setItem("lName", editedData["lastName"]);
            toast.success("Vehicle added successfully.");
            setSaveText("Saved!");

            setTimeout(() => {
                setSaveText("Save");
            }, 2000);
        } catch (error) {
            console.error("Error saving data:", error);
        }

    };

    const handleLogOut = () => {
        setLogoutText("Logging out...");
        setTimeout(() => {
            logout();
            window.location.href = "/login";
        }, 1000);
    };

    return (
        <div className='fcc'>
            <BackArrow path="/profile" />
            <div className="mt-12 fcc">
                <h1 className='text-2xl font-bold dark:text-gray-300 text-left w-screen p-6'>Personal Information</h1>
                <ErrorBoundary>
                    <form className='fcc gap-4 w-screen px-12'>
                        <div className="flex flex-row justify-center w-full items-center gap-4">
                            <FaUserGraduate className='w-6 h-6 text-[#B7B7B7] dark:text-gray-300' />
                            <div className="flex flex-col w-full">
                                <label htmlFor="email" className='text-[#B7B7B7] dark:text-gray-300 text-sm font-normal'>First Name</label>
                                <input
                                    name='firstName'
                                    type='name'
                                    value={editedData["firstName"]}
                                    onChange={handleEdit}
                                    onBlur={handleEmailBlur}
                                    className="w-full inline-block dark:bg-transparent dark:text-gray-300 pb-1 text-sm font-normal h-6 border-b border-[#B7B7B7] rounded-none"
                                />
                                <label htmlFor="email" className='text-[#B7B7B7] dark:text-gray-300 text-sm font-normal mt-3'>Last Name: </label>
                                <input
                                    name='lastName'
                                    type='name'
                                    value={editedData["lastName"]}
                                    onChange={handleEdit}
                                    onBlur={handleEmailBlur}
                                    className="w-full inline-block dark:bg-transparent dark:text-gray-300 pb-1 text-sm font-normal h-6 border-b border-[#B7B7B7] rounded-none"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row justify-center w-full items-center gap-4 opacity-45 pointer-events-none">
                            <MdEmail className='w-6 h-6 text-[#B7B7B7] dark:text-gray-300' />
                            <div className="flex flex-col w-full">
                                <label htmlFor="email" className='text-[#B7B7B7] dark:text-gray-300 text-sm font-normal mt-3'>Email: </label>
                                <input
                                    name='email'
                                    type="email"
                                    value={editedData["email"]}
                                    onChange={handleEdit}
                                    onBlur={handleEmailBlur}
                                    className="w-full inline-block dark:bg-transparent dark:text-gray-300 pb-1 text-sm font-normal h-6 border-b border-[#B7B7B7] rounded-none"
                                />
                            </div>
                        </div>
                        <div className="frc gap-2 w-full mt-6">
                            <div className={`fcc ${logoutText === "Logging out..." && "opacity-50 pointer-events-none"} rounded-md shadow-md w-fit h-fit px-4 py-1 light-gray-gradient font-bold`} onClick={handleLogOut}>
                                {logoutText}
                            </div>
                            <div className={`fcc ${saveText === "Saving..." && "opacity-50 pointer-events-none"} rounded-md shadow-md w-fit h-fit px-4 py-1 yellow-gradient font-bold`} onClick={handleSave}>
                                {saveText}
                            </div>
                        </div>
                    </form>
                </ErrorBoundary>
            </div>
        </div>
    );
}