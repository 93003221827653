import React, { useEffect, useState } from "react";

export const Popup = ({ content, closeButton }) => {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 1500);
    }, []);

    if (display) {
        return (
            <div className={`${display ? "fixed top-0 left-0 z-50 w-screen h-screen bg-gray-900/20 backdrop-blur-md flex justify-center items-center " : "hidden"}`}>
                <div className="bg-white dark:bg-gray-800 rounded-xl p-4 w-3/4 h-fit md:w-1/2 lg:w-1/3 xl:w-1/4 fcc shadow-lg">
                    {content}
                    <button
                        onClick={() => setDisplay(false)}
                        className="text-spotGray underline dark:text-gray-300 font-bold rounded-lg w-full mt-4 z-50 text-[10px]"
                    >
                        {closeButton}
                    </button>
                </div>
            </div>
        )
    } else {
        return null;
    }
}