import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PullToRefresh from 'react-pull-to-refresh';
import { TbTriangleInvertedFilled } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { fetchAllData } from "../../utility/fetcher";
import 'react-loading-skeleton/dist/skeleton.css'

export const Citations = () => {
    const [data, setData] = useState([]);
    const [unpaidOpen, setUnpaidOpen] = useState(true);
    const [paidOpen, setPaidOpen] = useState(false);
    const [appealedOpen, setAppealedOpen] = useState(false);
    const email = localStorage.getItem("email");

    const storeData = async () => {
        try {
            const url1 = { "userData": `/general/spotUsers?email=${email}` };
            const response = await fetchAllData(url1);
            const userData = response.userData;
            sessionStorage.setItem("userData", JSON.stringify(userData));

            const citations = userData.citations;
            setData(citations);
        } catch (error) {
            console.error("Error storing data:", error);
        }
    };

    const handleRefresh = async () => {
        setTimeout(async () => {
            await storeData();
        }, 200);
    };

    const individualParameter = (title, data) => {
        return (
            <div className="frc gap-1">
                <p className="text-spotGray dark:text-gray-200 font-bold font-xl">{title}: </p>
                <p className="text-spotGray dark:text-gray-200">{data}</p>
            </div>
        );
    };

    const handleCitationClick = (citation) => {
        sessionStorage.setItem("currentCitation", JSON.stringify(citation));
    };

    const citationList = (citationData, type, location) => {
        const message = type === "paid" ? "No paid citations found" : type === "appealed" ? "No removed citations found" : "No unpaid citations found";

        if (citationData.length === 0) {
            return (
                <div className="text-md font-medium text-left dark:text-gray-300">{message}</div>
            );
        }

        return (
            <div className="w-full flex flex-col gap-2 items-start justify-start mt-2">
                {citationData.map((citation, index) => {
                    const appeals = citation.appeals;

                    let status = type.toUpperCase()
                    for (let i in appeals) {
                        if (appeals[i].status === "pending") {
                            status = "Appeal Pending"
                            break;
                        } else if (appeals[i].status === "Denied") {
                            status = "Appeal Denied"
                            break;
                        } else if (appeals[i].status === "Approved") {
                            status = "Appeal Approved"
                            break;
                        }
                    }

                    return (
                        <Link to={`/citations/${location}/${citation.citationID}`} key={index} className="flex flex-row gap-8 justify-center w-full items-center h-fit bg-transparent border border-gray-100 dark:border-gray-600 self-center my-1 rounded-xl shadow-md text-spotGray text-left p-4 pr-0" onClick={() => handleCitationClick(citation)}>
                            <div className="flex flex-col justify-start items-start">
                                <h2 className="text-2xl text-spotGray dark:text-gray-300 font-medium">#{citation.citationID}</h2>
                                {individualParameter("Location", citation.location)}
                                {individualParameter("License Plate", citation.licensePlate)}
                                <div className="frc gap-4">
                                    {individualParameter("Spot", `${citation.lot}-${citation.spot}`)}
                                    {individualParameter("Fee", citation.fee)}
                                </div>
                                {individualParameter("Time Written", new Date(citation.timeWritten).toLocaleString())}
                                {individualParameter("Violation Type", citation.type)}
                                {individualParameter("Citation Status", status)}
                            </div>
                            <IoIosArrowForward className="text-gray-500 text-3xl flex flex-col justify-center items-center h-[100%]" />
                        </Link>
                    )
                })}
            </div>
        );
    };

    const unpaidCitations = () => {
        let unpaidCitations = [];
        data.forEach((citation) => {
            if (!citation.paid) {
                const appeals = citation.appeals;

                if (appeals.length !== 0) {
                    for (let i in appeals) {
                        if (appeals[i].status !== "Approved") {
                            unpaidCitations.push(citation);
                        }
                    }
                } else {
                    unpaidCitations.push(citation);
                }
            }
        });

        const location = unpaidCitations.length !== 0 ? unpaidCitations[0].location.replace(/\s/g, "") : null;
        sessionStorage.setItem("unpaidCitations", JSON.stringify(unpaidCitations));

        return citationList(unpaidCitations, "unpaid", location);
    };

    const paidCitations = () => {
        let paidCitations = [];
        data.forEach((citation) => {
            if (citation.paid) {
                paidCitations.push(citation);
            }
        });

        const location = paidCitations.length !== 0 ? paidCitations[0].location.replace(/\s/g, "") : null;
        sessionStorage.setItem("paidCitations", JSON.stringify(paidCitations));

        return citationList(paidCitations, "paid", location);
    };

    const appealedCitations = () => {
        let appealedCitations = [];
        data.forEach((citation) => {
            if (!citation.paid) {
                const appeals = citation.appeals;

                if (appeals.length !== 0) {
                    for (let i in appeals) {
                        if (appeals[i].status === "Approved") {
                            appealedCitations.push(citation);
                        }
                    }
                }
            }
        });

        const location = appealedCitations.length !== 0 ? appealedCitations[0].location.replace(/\s/g, "") : null;
        sessionStorage.setItem("appealedCitations", JSON.stringify(appealedCitations));

        return citationList(appealedCitations, "appealed", location);
    };

    const citationSection = (title) => {
        const handleClick = () => {
            if (title === "Unpaid") {
                setUnpaidOpen(!unpaidOpen);
            } else if (title === "Paid") {
                setPaidOpen(!paidOpen);
            } else {
                setAppealedOpen(!appealedOpen);
            }
        };

        const currentVar = title === "Unpaid" ? unpaidOpen : title === "Paid" ? paidOpen : appealedOpen;

        return (
            <div className="flex flex-col justify-start items-start mt-8 px-8">
                <div id={`${title.toLowerCase()}-section`} className="rounded-xl w-full h-fit p-2 flex flex-col justify-start items-start" onClick={handleClick}>
                    <div className="frc gap-2 px-0" >
                        <TbTriangleInvertedFilled className={`text-md text-gray-500 dark:text-gray-300 transition-all ease-in-out duration-100 ${currentVar ? null : "-rotate-90"}`} />
                        <div className="text-lg dark:text-gray-300">{title}</div>
                    </div >
                    {currentVar && (title === "Unpaid" ? unpaidCitations() : title === "Paid" ? paidCitations() : appealedCitations())}
                </div >
            </div >
        );
    };

    useEffect(() => {
        storeData();
    }, []);

    try {
        return (
            <div className="scroll-smooth flex flex-col h-screen">
                <h1 className="mt-8 font-bold text-spotGray dark:text-gray-300 text-3xl text-center">Pending Citations</h1>
                <div className="pb-32">
                    {citationSection("Unpaid")}
                    {citationSection("Paid")}
                    {citationSection("Removed")}
                </div>
            </div>
        );
    } catch (error) {
        console.error("Error rendering Citations:", error);
        return (
            <div className="scroll-smooth flex flex-col">
                <PullToRefresh
                    onRefresh={handleRefresh}
                    pullDownThreshold={80}
                    className="h-full"
                    resistance={2.5}
                >
                    <h1 className="scroll-smooth mt-8 font-bold text-spotGray dark:text-gray-300 text-3xl text-center">Pending Citations</h1>
                    <div className="flex flex-col justify-start items-start mt-8 px-8" onClick={() => setUnpaidOpen(!unpaidOpen)}>
                        <div id="unpaid-section" className="rounded-xl w-full h-fit p-2 flex flex-col justify-start items-start">
                            <div className="frc gap-2 px-0">
                                <TbTriangleInvertedFilled className={`text-md text-gray-500 ${unpaidOpen === true ? null : "-rotate-90"}`} />
                                <div className="text-lg">Unpaid</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start mt-8 px-8" onClick={() => setPaidOpen(!paidOpen)}>
                        <div id="unpaid-section" className="rounded-xl w-full h-fit p-2 flex flex-col justify-start items-start">
                            <div className="frc gap-2 px-0">
                                <TbTriangleInvertedFilled className={`text-md text-gray-500 ${paidOpen === true ? null : "-rotate-90"}`} />
                                <div className="text-lg text-spotGray dark:text-gray-300">Paid</div>
                            </div>
                        </div>
                    </div>
                </PullToRefresh >
            </div >
        )
    }
}
