import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { fetchAllData } from "../utility/fetcher";
import ViolationCard from "./mapView/ViolationCard";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./mapView/Map.css";
import { Popup } from "../utility/Popup";
import navLogo from "../../assets/navLogo.png"

// Mapbox Tokens
mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmhhZ2VydHkzMiIsImEiOiJjbHYyd25vdGEwbXhoMmtwN3hkZGZ6ZmxmIn0.8j4S80-LC8wX7mLHwWrc_g";
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// Map Creation
const SoloMap = ({
    styling,
    zoom,
    orgID
}) => {
    const [isMapReady, setIsMapReady] = useState(false);
    const [map, setMap] = useState(null);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [levelStates, setLevelStates] = useState({});
    const [selectedViolation, setSelectedViolation] = useState(null);
    const [isStyleLoaded, setIsStyleLoaded] = useState(false);
    const [listData, setListData] = useState([]);
    const [currentListData, setCurrentListData] = useState(listData);
    const isDarkModeActive = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    let maxLevel = 0;
    let minLevel = 0;

    for (let i in listData) {
        if (parseInt(listData[i].level) > maxLevel) {
            maxLevel = parseInt(listData[i].level)
        }
        if (parseInt(listData[i].level) < minLevel) {
            minLevel = parseInt(listData[i].level)
        }
    }

    useEffect(() => {
        let intervalId = null;

        const getNewData = async () => {
            const newData = await fetchAllData({ "spotData": `/${orgID}/spots` });
            setCurrentListData(newData.spotData);
        };

        // Every ten seconds, update the map data. This is a very expensive operation. Not in regard to the code, but in regard to the cost of the API calls.
        const startPeriodicDataGetter = () => {
            intervalId = setInterval(async () => {
                await getNewData();
            }, 20000); //! This has direct influence on our costs. Think deeply if you are wanting to change this.
        };

        const stopPeriodicDataGetter = () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
                intervalId = null;
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                startPeriodicDataGetter();
            } else {
                stopPeriodicDataGetter();
            }
        };

        // Set up event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Initial start when the component is first mounted
        if (document.visibilityState === 'visible') {
            startPeriodicDataGetter();
        }

        // Cleanup function
        return () => {
            stopPeriodicDataGetter();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line
    }, []);

    navigator.permissions.query({ name: "geolocation" })
        .then((result) => {
            if (result.state === "denied") {
                // Reprompt for geolocation permissions
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Success callback
                        console.log("Geolocation permissions granted");
                        // Do something with the position data
                    },
                    (error) => {
                        // Error callback
                        console.error("Geolocation permissions denied");
                    }
                );
            }
        })
        .catch((error) => {
            console.error(error);
        });

    if (navigator.geolocation) {
        navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state === "granted") {
                    //If granted then you can directly call your function here
                } else if (result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            // Code to execute when geolocation is successfully obtained
                            const { latitude, longitude } = position.coords;
                            console.log("Latitude:", latitude);
                            console.log("Longitude:", longitude);
                        },
                        function (error) {
                            console.error(error);
                        }
                    );
                } else if (result.state === "denied") {
                    //If denied then you have to show instructions to enable location
                }
                result.onchange = function () {
                    console.log(result.state);
                };
            });
    } else {
        alert("Sorry Not available!");
    }

    // Default settings
    const startSpotCoordinates = [-112.81619, 40.30045] // startSpot ? startSpot : uniCoordinates;
    const mapZoom = 20 //zoom ? zoom : defaultZoom;
    const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const baseZoom = 18; // Line Size and base zoom have an inverse relationship
    const baseWidth = 1;
    const mapCss = styling ? styling : { // Default styling
        flex: "1",
        top: "0%",
        right: "0px",
        position: "relative",
        touchAction: "none",
        width: "100vw",
        height: "105vh",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
    };

    // Color Scheme
    if (darkMode === true) {
        var colorScheme = "mapbox://styles/mapbox/dark-v10?"
    } else {
        var colorScheme = "mapbox://styles/mapbox/light-v10"
    }

    const handleCloseViolationCard = () => {
        //const violationColor = selectedViolation.violationType === "N/A" ? "#aad7a4" : "#D1807D";
        //map.setPaintProperty(`polygon-layer-${selectedViolation.licensePlate}`, "fill-color", violationColor); // Change fill-color back to original
        setSelectedViolation(null);
    };

    // Map Object
    useEffect(() => {
        // Create map
        const newMap = new mapboxgl.Map({
            container: "map-container",
            style: colorScheme,
            center: startSpotCoordinates,
            zoom: mapZoom
        });

        // Add geolocate control to the map.
        newMap.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true
            })
        );

        // Create map
        setMap(newMap);
        setIsMapReady(true);

        // Add features
        newMap.on("load", async () => {
            setIsStyleLoaded(true);
            await addAllPolygons(newMap);
            addLines(newMap);
        });

        return () => {
            newMap.remove();
        };
    }, []);

    useEffect(() => {
        if (map && isStyleLoaded) {
            addAllPolygons(map);
        }
        // eslint-disable-next-line
    }, [currentListData, currentLevel]);

    useEffect(() => {
        if (map && isStyleLoaded) {
            addLines(map);
        }
        // eslint-disable-next-line
    }, [currentLevel]);

    const addAllPolygons = async (map) => {
        const paths = {
            "orgData": `/general/organizations?orgID=${orgID}`,
            "spotData": `/${orgID}/spots`,
        }
        // setIsMapReady(false);
        const data = await fetchAllData(paths);
        const spotData = data.spotData

        // Convert your list data into GeoJSON features
        const coordinatesArray = spotData.map((item) => [
            JSON.parse(item.coordinates),
            item.licensePlate,
            item.lot,
            item.spot,
            item.spotID,
            item.level,
        ]);

        const features = coordinatesArray.map(([coordinates, licensePlate, lot, spot, spotID, level]) => {
            let fillColor;
            if (licensePlate === "") {
                fillColor = "#aad7a4";
            } else {
                fillColor = isDarkModeActive ? "#666666" : "#C9C9C9";
            }

            // Return a GeoJSON feature if it's on the current level
            if (currentLevel.toString() === level) {
                return {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [coordinates]
                    },
                    properties: {
                        id: `polygon-layer-${spotID}`,
                        spot: spot,
                        lot: lot,
                        coordinates: coordinates,
                        fillColor: fillColor,
                        spotID: spotID,
                        level: level
                    }
                };
            } else {
                return null
            }
        }).filter(feature => feature !== null);

        // Create a GeoJSON feature collection
        const featureCollection = {
            type: "FeatureCollection",
            features: features
        };

        // Add or update the source and layer on the map
        if (map.getSource('polygons')) {
            map.getSource('polygons').setData(featureCollection);
        } else {
            map.addSource('polygons', {
                type: 'geojson',
                data: featureCollection
            });

            map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: 'polygons',
                paint: {
                    'fill-color': ['get', 'fillColor'],
                    'fill-opacity': 1,
                    'fill-outline-color': 'transparent',
                }
            });
        }

        // Calls the violation card when a polygon is clicked
        map.on('click', 'polygon-layer', (e) => {
            const clickedFeature = e.features[0];
            const properties = clickedFeature.properties;

            const { spot, lot, spotID, level, coordinates } = properties;
            setSelectedViolation({ spot, lot, spotID, level, coordinates });
        });

        // Handle clicks outside polygons to deselect
        map.on('click', (e) => {
            const features = map.queryRenderedFeatures(e.point, { layers: ['polygon-layer'] });

            if (!features.length) {
                // Clear all selections
                handleCloseViolationCard();
                setSelectedViolation(null); // Clear the selected violation
            }
        });
    };

    function jsonBuilder(arr) {
        var finalJson = []

        arr.forEach((lot) => {
            for (let i in lot) {
                const level = lot[i].level
                const lines = lot[i].lines
                if (currentLevel.toString() === level) {
                    for (let i in lines) {
                        finalJson.push({
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "coordinates": lines[i],
                                "type": "LineString"
                            }
                        })
                    }
                }
            }
        })

        return finalJson
    }

    //Add lines to the map. Gets its data from the jsonBuilder function 
    const addLines = async (map) => {
        const paths = {
            "orgData": `/general/organizations?orgID=${orgID}`,
            "lineData": `/${orgID}/lines`
        }
        const data = await fetchAllData(paths);
        const lineData = data.lineData //TODO make this not duped from above. store this during the first fetch
        // Remove existing layer if it exists
        if (map.getLayer('route')) {
            map.removeLayer('route');
        }

        // Remove existing source if it exists
        if (map.getSource('route')) {
            map.removeSource('route');
        }

        map.addSource('route', {
            'type': 'geojson',
            'data': {
                "type": "FeatureCollection",
                "features": jsonBuilder(lineData)
            }
        });

        map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'square',
                'line-sort-key': 10
            },
            paint: {
                'line-color': isDarkModeActive ? "#FFF9C4" : "#ffe374",
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [0, baseWidth * Math.pow(2, (0 - baseZoom))],
                        [24, baseWidth * Math.pow(2, (24 - baseZoom))]
                    ]
                }
            }
        });

        const orgCoordinates = JSON.parse(data.orgData.orgCoordinates);
        map.flyTo({
            center: {
                lng: orgCoordinates[0],
                lat: orgCoordinates[1]
            },
            zoom: data.orgData.defaultMapZoom,
            essential: true,
            duration: 0
        });
        setIsMapReady(true);
    };

    const [open, setOpen] = useState(false);
    const Diamond = ({ fill, level }) => {
        return (
            <div className="absolute group cursor-pointer">
                {/* Tooltip */}
                {open && (
                    <p className={`absolute ${level === 0 ? "right-[-183%]" : "right-[-120%]"} top-1/2 transform -translate-y-1/2 bg-gray-600 px-2 h-6 fcc font-bold text-[10px] rounded-md rotate-180`}>
                        {level === 0 ? "Ground Level" : `Level ${level + 1}`}
                    </p>
                )}
                {/* Diamond */}
                <svg
                    width="1861"
                    height="976"
                    viewBox="0 0 1861 976"
                    className={`w-12 h-auto`}
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setOpen(!open)}
                    style={{
                        fill: isDarkModeActive ? fill ? "#C0C2C6" : "#343332" : fill ? "#4F545C" : "white",
                        transition: "fill 0.3s ease",
                    }}
                >
                    <path
                        d="M965.063 63.3257L1753.23 409.251C1821.82 439.352 1821.82 536.648 1753.23 566.749L965.063 912.674C943.035 922.342 917.965 922.342 895.937 912.674L107.766 566.749C39.1833 536.648 39.1832 439.352 107.766 409.251L895.937 63.3257C917.965 53.6578 943.035 53.6578 965.063 63.3257Z"
                        stroke={`${isDarkModeActive ? "#C0C2C6" : "#4F545C"}`}
                        strokeWidth="90"
                    />
                </svg>
            </div>
        );
    };

    const changeLevel = (index) => {
        const newLevelStates = { ...levelStates };
        Object.keys(newLevelStates).forEach((key) => {
            newLevelStates[key] = false;
        });
        newLevelStates[index] = !newLevelStates[index];
        setCurrentLevel(index);
        setLevelStates(newLevelStates);
    }

    const popupContent = (
        <div className="fcc gap-2 font-rubik text-center">
            <h1 className="text-2xl text-spotGray dark:text-gray-300">Looking for a spot?</h1>
            <div className="frc gap-4">
                <p className="text-md w-full text-spotGray dark:text-gray-300">See where else you can park<br />with the Spot Parking App.</p>
                <a href="https://nav.spotparking.app/" target="_blank" rel="noreferrer">
                    <img src={navLogo} alt="Spot Parking Logo" className="w-20 h-auto shadow-lg" />
                </a>
            </div>
        </div>
    );

    return (
        <div id="map-container" style={mapCss}>
            {!isMapReady ? (
                <div className="fcc h-screen w-screen">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <>
                    {Object.entries(levelStates).length > 1 && (
                        <div className="flex flex-col absolute bottom-[160px] right-6 z-20 gap-2 text-2xl text-white" onClick={() => setOpen(!open)}>
                            <div className={`ml-16 fcc rotate-180 ${open ? "gap-8" : "gap-[10px]"} transition-all duration-500 ease-in-out`}>
                                {Object.entries(levelStates).map(([_, fill], index) => (
                                    <div onClick={() => changeLevel(index)} key={index} className={`${!open && "pointer-events-none"}`}>
                                        <Diamond fill={fill} level={index} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {selectedViolation && (
                        <div>
                            <ViolationCard violation={selectedViolation} onClose={handleCloseViolationCard} />
                        </div>
                    )}
                    <div className="fcc gap-2 absolute top-4 left-4 p-4 shadow-lg bg-gray-300/20 w-fit h-fit backdrop-blur-md rounded-lg">
                        <div className="frc">
                            <div className="rounded-full bg-[#C9C9C9] shadow-md w-8 h-8"></div>
                            <p className="text-sm ml-2">Occupied</p>
                        </div>
                        <div className="frc">
                            <div className="rounded-full bg-[#aad7a4] shadow-md w-8 h-8"></div>
                            <p className="text-sm ml-2">Available</p>
                        </div>
                    </div>
                    <Popup content={popupContent} closeButton={"No thanks"} />
                </>
            )}
        </div>
    )
};

export default SoloMap;
