import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoPerson } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import { PiMapPin } from "react-icons/pi";
import { PiMapPinFill } from "react-icons/pi";
import { PiReceipt } from "react-icons/pi";
import { PiReceiptFill } from "react-icons/pi";
import { listViewIcon } from "../../assets/listViewIcon";

const NavBar = () => {
    const [page, setPage] = useState("List View");
    const [animatedIcon, setAnimatedIcon] = useState(null);
    const [color, setColor] = useState('#ffffff'); // Default color
    const [fill, setFill] = useState("transparent")
    const isNotIphone = !navigator.userAgent.match(/iPhone/i);
    const bottom = isNotIphone ? "bottom-6" : "bottom-8";
    const navigate = useNavigate();

    // Dark Mode Helper
    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleDarkModeChange = (e) => {
            if (e.matches) {
                setColor('#d4d7dc'); // Dark mode color
                setFill('#d4d7dc')
            } else {
                setColor('#4b5563'); // Light mode color
                setFill('#4b5563')
            }
        };

        // Set initial color based on current theme
        if (darkModeMediaQuery.matches) {
            setColor('#d4d7dc'); // Dark mode color
            setFill('#d4d7dc')
        } else {
            setColor('#4b5563'); // Light mode color
            setFill('#4b5563')
        }

        // Add event listener
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

        // Clean up event listener
        return () => {
            darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
    }, []);

    const inactiveIcons = {
        "Profile": <IoPersonOutline />,
        "List View": listViewIcon({ width: 32, height: 32, color: color, fill: "transparent" }),
        "Map View": <PiMapPin />,
        "Citations": <PiReceipt />,
    };

    const activeIcons = {
        "Profile": <IoPerson />,
        "List View": listViewIcon({ width: 32, height: 32, color: color, fill: fill }),
        "Map View": <PiMapPinFill />,
        "Citations": <PiReceiptFill />,
    };

    const HeaderLink = (title, link) => {
        const handleIconClick = () => {
            setAnimatedIcon(title);
            handleClick(title);

            // Reset animation state after animation ends
            setTimeout(() => {
                setAnimatedIcon(null);
            }, 300); // Duration should match the animation time
        };

        const handleClick = (title) => {
            setPage(title);
            navigate(link);
            sessionStorage.setItem("currentPage", title);
        };

        return (
            <div
                className={`flex items-center text-4xl text-gray-600 dark:text-gray-400 z-50 p-2 transition-transform ${animatedIcon === title ? 'animate-shrink-grow' : ''}`}
                onClick={handleIconClick}
            >
                {page === title ? activeIcons[title] : inactiveIcons[title]}
            </div>
        );
    };

    return (
        <header className={`fixed z-50 ${bottom} left-1/2 -translate-x-1/2 w-[90%] flex justify-center items-center bg-white/60 dark:bg-spotGray/60 backdrop-blur-md rounded-[40px] shadow-xl text-white border h-[70px] border-lightGrey dark:border-gray-700 print:hidden`}>
            <div className="w-full flex flex-row mx-[17px] justify-between items-center">
                {HeaderLink("Profile", "/profile")}
                {HeaderLink("List View", "/")}
                {HeaderLink("Map View", "/MapView")}
                {HeaderLink("Citations", "/citations")}
            </div>
        </header>
    );
};

export default NavBar;