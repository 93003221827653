// * HOME PAGE
import React, { useEffect, useState } from "react";
import Header from "./Header";
import PullToRefresh from 'react-pull-to-refresh';
import Skeleton from 'react-loading-skeleton'
import ErrorBoundary from "../../../utility/ErrorBoundary";
import { fetchAllData } from "../../../utility/fetcher";
import { logout } from "../../../utility/logout";
import 'react-loading-skeleton/dist/skeleton.css'
import "./lotView.css";
import { getCurrentLocation } from "../../../utility/navigation";

function LotView() {
    const [loading, setLoading] = useState(true);
    const [filterOption, setFilterOption] = useState("Lot");
    const [currentCoords, setCurrentCoords] = useState({ latitude: null, longitude: null });
    const currentOrg = "Brigham Young University";
    const currentOrgName = currentOrg.replace(/\s/g, '+');
    const user = localStorage.getItem("username");
    const [listData, setListData] = useState(null);
    const capitalizedUser = user.charAt(0).toUpperCase() + user.slice(1);
    const userData = JSON.parse(localStorage.getItem("userData"));
    sessionStorage.setItem("user", capitalizedUser);

    var mt = "mt-16"
    const isNotIphone = !navigator.userAgent.match(/iPhone/i);
    if (isNotIphone) {
        mt = "mt-0"
    }

    const storeData = async () => {
        try {
            const orgID = "000004";
            const urls = {
                "violationData": `/${orgID}/spots`,
                "lineData": `/${orgID}/lines`,
                "uniData": `/general/organizations?orgID=${orgID}`,
            }

            const data = await fetchAllData(urls);

            sessionStorage.setItem("uniData", JSON.stringify(data.uniData));
            sessionStorage.setItem("lineData", JSON.stringify(data.lineData));
            sessionStorage.setItem("violationData", JSON.stringify(data.violationData));

            setListData(data.violationData);
            setLoading(false);
        } catch (error) {
            console.error("Error storing data:", error);
        }
    };

    const loader = () => {
        const loaderItem = () => {
            const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            const backgroundColor = isDarkMode ? "#606060" : "#ebebeb";
            const highlightColor = isDarkMode ? "#888888" : "#F5F5F5";

            return (
                <div className="flex flex-row justify-between w-full items-center h-16 bg-white border dark:bg-gradient-to-r dark:from-[#3d3d3d] dark:to-[#5c5c5c] dark:border-gray-600 self-center rounded-xl text-spotGray text-left">
                    <div className="flex flex-col justify-start items-start mr-auto ml-4">
                        <Skeleton height={10} width={0.14 * window.innerWidth} borderRadius={8} baseColor={backgroundColor} highlightColor={highlightColor} />
                        <Skeleton height={20} width={0.25 * window.innerWidth} borderRadius={8} baseColor={backgroundColor} highlightColor={highlightColor} />
                    </div>
                    <div className="flex flex-row mr-4">
                        <Skeleton height={15} width={0.35 * window.innerWidth} borderRadius={20} baseColor={backgroundColor} highlightColor={highlightColor} />
                    </div>
                </div>
            );
        }

        return (
            <div className="flex flex-col gap-2">
                {Array.from({ length: 12 }).map((_, index) => (
                    <div key={index}>
                        {loaderItem()}
                    </div>
                ))}
            </div>
        )
    }

    // // Fetch the current location
    // const fetchCurrentLocation = async () => {
    //     try {
    //         const coords = await getCurrentLocation();
    //         return coords
    //     } catch (error) {
    //         console.error('Error getting current location:', error);
    //     }
    // };

    // Start by fetching data
    useEffect(() => {
        // // Get line and violation data for all unis within a certain radius
        // const getCloseUnis = async () => {
        //     const coords = await fetchCurrentLocation();
        //     console.log(coords)

        //     const path = "/functions/getRelevantListData";
        //     const queryParams = `?latitude=${coords.latitude}&longitude=${coords.longitude}`;
        //     const url = `${path}${queryParams}`;
        //     const response = await fetchAllData({ "closestUnis": url });
        //     console.log(response.closestUnis)
        // }

        const currentData = sessionStorage.getItem("violationData");

        if (currentData) {
            console.log("Data already exists in sessionStorage");
            setListData(JSON.parse(currentData));
            setLoading(false);
        } else {
            storeData();
        }
    }, []);

    if (loading) {
        return (
            <div className="fcc w-screen h-auto">
                <Header defaultSelection={"Lot"} handleSort={setFilterOption} onChange={(e) => setFilterOption(e.target.value)} />
                <div className={`w-[95%] ${mt}`}>
                    {loader()}
                </div>
            </div>
        );
    }

    let userOrg = "";
    for (let i in userData) {
        if (userData[i].Name === "custom:organization") {
            userOrg = userData[i].Value;
        }
    }

    // Handle filter change
    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    const handleRefresh = () => {
        setLoading(true);
        storeData();
    }

    let lotArray = [];
    if (listData) {
        const lotMap = {};
        listData.forEach((item) => {
            const lot = item.lot;
            if (!lotMap[lot]) {
                lotMap[lot] = [];
            }
            if (item.timeParked !== "") {
                lotMap[lot].push(item);
            }
        });
        lotArray = Object.entries(lotMap).map(([lot, items]) => ({ lot, items }));
    }


    // const lotArray = listData ? Object.entries(listData.reduce((acc, item) => {
    //     const lot = item.Lot;
    //     acc[lot] = acc[lot] || [];
    //     acc[lot].push(item);
    //     return acc;
    // }, {})).map(([lot, items]) => ({ lot, items })) : [];

    // // Sort the lotArray based on the filterOption
    // let sortedLotArray = [...lotArray];
    // if (filterOption === "Spots Left") {
    //     sortedLotArray.sort((a, b) => b.spotsLeft - a.spotsLeft);
    // } else if (filterOption === "Nearest") {
    //     // Add code to sort based on nearest location
    // } else if (filterOption === "Lot") {
    //     sortedLotArray.sort((a, b) => a.lot.localeCompare(b.lot));
    // }
    const uniData = JSON.parse(sessionStorage.getItem("uniData"));
    const violationData = JSON.parse(sessionStorage.getItem("violationData"));

    const LotItem = (lotObj) => {
        const [open, setOpen] = useState(false);
        const lots = uniData["lots"];
        const currentLot = lotObj["lot"];
        const filteredLot = lots.filter((lot) => lot.lotNum === currentLot);
        const lotName = filteredLot[0].lotName;
        const lotCapacity = violationData.filter(item => item.lot === filteredLot[0]["lotNum"]).length;
        const numCars = lotObj["items"].length;
        const percentage = Math.round((numCars / lotCapacity) * 100);
        const spotsLeft = lotCapacity - numCars;

        const barChart = (percentage) => {
            return (
                <div className="flex flex-col">
                    <div key={"percentage"} className="text-left text-sm text-spotGray dark:text-gray-200">{percentage}% Filled</div>
                    <div key={"bar"} className="barchart-container">
                        <div key={"fill"} className="barchart-fill" style={{ width: `${percentage}%`, backgroundColor: "#FFE374" }}></div>
                    </div>
                </div>
            )
        }

        // onClick={() => setOpen(!open)}
        // it had: shadow-md bg-white border border-gray-100 dark:border-gray-600

        return (
            <div to={`/listView/${lotObj["lot"]}`}>
                <div className={`flex flex-col justify-between items-center transition-all ease-in-out duration-100 ${open ? "h-32" : "h-16"} bg-white border border-gray-300 self-center dark:bg-gradient-to-r dark:from-[#3d3d3d] dark:to-[#5c5c5c] dark:border-gray-600 rounded-xl text-spotGray text-left`} >
                    <div className="flex flex-row justify-between w-full items-center mt-[7px]">
                        <div className="flex flex-col pl-4 w-full max-w-xs overflow-hidden">
                            <h2 className="text-lg font-bold text-spotGray dark:text-gray-200 whitespace-nowrap overflow-hidden text-ellipsis">Lot {lotObj["lot"]}:&nbsp;
                                <span className="text-base font-medium">{lotName}</span>
                            </h2>
                        </div>
                        <div className="frc w-[50%]">
                            <div className="flex flex-row items-center">
                                {barChart(percentage)}
                            </div>
                            <div className="fcc gap-0 mr-4 dark:text-gray-200">
                                <div className="font-bold py-0">{spotsLeft}</div>
                                <div className="my-0">Left</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${open ? "scale-100 opacity-100" : "scale-0 opacity-0"} transition-all ease-in-out duration-200 p-4 text-center text-spotGray dark`}>
                        We need to find something to put here, or redesign this page
                    </div>
                </div>
            </div>
        );
    }

    return (
        <PullToRefresh onRefresh={handleRefresh} >
            <div className="fcc w-screen h-auto">
                <Header defaultSelection={"Lot"} handleSort={setFilterOption} onChange={(e) => setFilterOption(e.target.value)} />
                <div className={`w-[95%] ${mt}`}>
                    <ErrorBoundary>
                        {loading ? loader() :
                            <div className="flex flex-col gap-2">
                                {lotArray?.map((lotObj) => (
                                    <LotItem key={lotObj.lot} {...lotObj} />
                                ))}
                            </div>
                        }
                    </ErrorBoundary>
                </div>
            </div >
        </PullToRefresh >
    );
}

export default LotView;