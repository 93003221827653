import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toaster, toast } from 'sonner'
import { BackArrow } from '../../utility/BackArrow.js';
import { fetchAllData, postData } from '../../utility/fetcher.js';
import "../../../styles.css";
import './profile.css';
import { navArrow } from '../../../assets/navArrow.js';
import { openMapWithDirections } from '../../utility/openMap.js';

function validateVin(vin) {
    const re = /^[A-HJ-NPR-Z\d]{8}[\dX][A-HJ-NPR-Z\d]{2}\d{6}$/i;
    const result = re.test(vin);
    return result;
}

export const Vehicles = () => {
    // Get user info
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const [purchasePermitOpen, setPurchasePermitOpen] = useState(false);
    const [addButton, setaddButton] = useState("Add New Vehicle");
    const [addPrompt, setaddPrompt] = useState("Got a new car?");
    const [addReady, setaddReady] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [saveButtonText, setSaveButtonText] = useState("Save");

    const getorgNameByID = async (orgID) => {
        const path = { "orgData": `/general/organizations?orgID=${orgID}` };
        const response = await fetchAllData(path);
        const orgData = response.orgData;
        return orgData.orgName;
    }

    // Handlers
    const initialData = {
        make: "",
        model: "",
        type: "",
        color: "",
        licensePlate: "",
        vin: ""
    }

    const [editedData, setEditedData] = useState(initialData);
    const handleEdit = (e) => {
        setIsValid(true);
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });

        if (Object.values(editedData).every(value => value !== "")) {
            setaddReady(true);
        } else {
            setaddReady(false);
        }
    };

    //TODO Talk to Dean about a GET handler for the permit database
    // const validatePlate = async (plate) => {
    //     const path = { "plate": `/${orgID}?licensePlate=${plate}` };
    //     const response = await fetchAllData(path);
    //     return response;
    // }

    const singleVehicle = (vehicle) => {
        const parkedAtOrgID = vehicle.parkedAt.split('-')[0]
        const parkedAtSpotID = vehicle.parkedAt.split('-').slice(1, 3).join('-');
        const [parkedAtOrgName, setParkedAtOrgName] = useState("");
        const [coords, setCoords] = useState("loading...");

        useEffect(() => {
            const fetchOrgName = async () => {
                const orgName = await getorgNameByID(parkedAtOrgID);
                setParkedAtOrgName(orgName);
            };

            const fetchCoords = async () => {
                const path = { "spotData": `/000004/spots?spotID=${parkedAtSpotID}` };
                const response = await fetchAllData(path);
                const coords = JSON.parse(response.spotData.coordinates)[0];
                setCoords(coords);
            }

            fetchOrgName();
            fetchCoords();
        }, [parkedAtOrgID]);

        const [secondItem, thirdItem] = vehicle.parkedAt.split('-').slice(1, 3);

        return (
            <div className="border border-gray-100 dark:border-gray-600 shadow-md rounded-md p-4 flex flex-col gap-2 text-spotGray dark:text-gray-300 relative">
                <Link to={`/vehicles/${vehicle.licensePlate}`} onClick={() => sessionStorage.setItem("vehicleData", JSON.stringify(vehicle))} className="w-full h-full">
                    <div className="w-full flex flex-row gap-2">
                        <label htmlFor="organization" className='whitespace-nowrap'>Make: </label>
                        <div className="text-sm font-normal mt-[2px]">{vehicle.make}</div>
                    </div>
                    <div className="w-full flex flex-row gap-2">
                        <label htmlFor="organization" className='whitespace-nowrap'>Model: </label>
                        <div className="text-sm font-normal mt-[2px]">{vehicle.model}</div>
                    </div>
                    <div className="w-full flex flex-row gap-2">
                        <label htmlFor="organization" className='whitespace-nowrap'>Type: </label>
                        <div className="text-sm font-normal mt-[2px]">{vehicle.type}</div>
                    </div>
                    <div className="w-full flex flex-row gap-2">
                        <label htmlFor="organization" className='whitespace-nowrap'>Color: </label>
                        <div className="text-sm font-normal mt-[2px]">{vehicle.color}</div>
                    </div>
                    <div className="w-full flex flex-row gap-2">
                        <label htmlFor="organization" className='whitespace-nowrap'>License Plate: </label>
                        <div className="text-sm mt-[2px] font-mono">{vehicle.licensePlate}</div>
                    </div>
                    <div className="w-full flex flex-row gap-2">
                        <label htmlFor="organization" className='whitespace-nowrap'>VIN: </label>
                        <div className="text-sm font-normal mt-[2px] font-mono">{vehicle.vin}</div>
                    </div>
                    <div className="w-full flex flex-row gap-2 items-center">
                        <label htmlFor="organization" className='whitespace-nowrap'>Location: </label>
                        <div className="text-sm font-normal mt-[2px]">
                            {coords === "loading..." ? coords :
                                <div className="flec flex-col">
                                    <div>{parkedAtOrgName}</div>
                                    <div>{" Spot " + secondItem + "-" + thirdItem}</div>
                                </div>
                            }
                        </div>
                    </div>
                </Link>
                {coords === "loading..." ? null :
                    <div className="fcc absolute z-50 bottom-0 right-0 border-t border-l rounded-tl-lg p-2" onClick={() => openMapWithDirections(coords[1], coords[0])}>
                        {navArrow(25, 25, "#4B5563")}
                    </div>
                }
            </div>
        )
    }

    function resetItems() {
        setaddButton("Add New Vehicle");
        setaddPrompt("Got a new car?");
        setSaveButtonText("Save");
        setPurchasePermitOpen(false);
        setaddReady(false);
        setEditedData(initialData);
    }

    const handleAddButtonClick = () => {
        addButton === "Add New Vehicle" ?
            setaddButton("Close") :
            resetItems();
        addPrompt === "" ? setaddPrompt("Got a new car?") : setaddPrompt("");
        setPurchasePermitOpen(!purchasePermitOpen)
    }

    const handleSaveNewVehicle = async () => {
        for (let i in editedData) {
            if (editedData[i] === "") {
                toast.error("Please fill out all fields.");
                return;
            }
        }

        if (!isValid) {
            toast.error("Invalid VIN.");
            return;
        }

        setSaveButtonText("Saving...");

        const objBuild = {
            color: editedData.color,
            licensePlate: editedData.licensePlate.trim(),
            make: editedData.make,
            model: editedData.model.trim(),
            type: editedData.type,
            vin: editedData.vin.trim(),
            parkedAt: ""
        }

        userData.settings.vehicles.push(objBuild);

        try {
            console.log("Data to save:", userData);
            const path = "/general/spotUsers";
            const response = await postData(path, userData);
            console.log(response.message)
        } catch (error) {
            console.error("Error saving data:", error);
        }

        sessionStorage.setItem("userData", JSON.stringify(userData));
        resetItems();
        toast.success("Vehicle added successfully.");
    }

    const vehicleData = userData.settings.vehicles;

    return (
        <div className='fcc p-6'>
            <BackArrow path="/profile" />
            <div className="w-full flex flex-col gap-6 mt-12 pb-32 items-center">
                <div className={`flex flex-col gap-4 w-full`}>
                    {vehicleData.map((vehicle, index) => (
                        <div key={`permit-${index}`}>
                            {singleVehicle(vehicle)}
                        </div>)
                    )}
                </div>
                <div className={`fcc py-4 w-full tile dark:border-gray-600`}>
                    <div className={`flex flex-col gap-4 p-2 text-spotGray dark:text-gray-300 overflow-hidden ${purchasePermitOpen ? "max-h-fit" : "max-h-0 -mb-5 opacity-0"}`}>
                        <h1 className='font-bold text-2xl text-spotGray dark:text-gray-300 w-full text-center'>Add a Vehicle</h1>
                        <div className='frc gap-2'>
                            <label htmlFor="make">Make:</label>
                            <select
                                name='make'
                                value={editedData["make"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 dark:border-gray-600 dark:bg-transparent rounded-md">
                                {carBrands.map((brand, index) => (
                                    <option key={`brand-${index}`} value={brand}>{brand}</option>
                                ))}
                            </select>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="permit">Model: </label>
                            <input
                                name='model'
                                value={editedData["model"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 dark:border-gray-600 dark:bg-transparent rounded-md">
                            </input>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="permit">Type: </label>
                            <select
                                name='type'
                                value={editedData["type"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 dark:border-gray-600 dark:bg-transparent rounded-md">
                                {vehicleTypes.map((type, index) => (
                                    <option key={`type-${index}`} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="permit">Color: </label>
                            <select
                                name='color'
                                value={editedData["color"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 dark:border-gray-600 dark:bg-transparent rounded-md">
                                {vehicleColors.map((color, index) => (
                                    <option key={`color-${index}`} value={color}>{color}</option>
                                ))}
                            </select>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="permit" className='min-w-fit'>License Plate: </label>
                            <input
                                name='licensePlate'
                                value={editedData["licensePlate"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border font-mono border-gray-200 dark:border-gray-600 dark:bg-transparent rounded-md">
                            </input>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="permit" className='min-w-fit'>VIN #: </label>
                            <input
                                name='vin'
                                value={editedData["vin"]}
                                onChange={handleEdit}
                                onBlur={() => setIsValid(validateVin(editedData["vin"]))}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border font-mono border-gray-200 dark:border-gray-600 dark:bg-transparent rounded-md">
                            </input>
                        </div>
                        <div className="text-xs font-normal text-red-500 w-full text-center">
                            {!isValid && 'Invalid VIN'}
                        </div>
                    </div>
                    <div className={`frc gap-4 text-spotGray dark:text-gray-300`}>
                        <div>{addPrompt}</div>
                        <div
                            className={`shadow-md rounded-md transition-all duration-200 ease-in-out text-spotGray font-semibold p-1 px-4 cursor-pointer ${addReady && addButton === "Purchase" ? "yellow-gradient" : "light-gray-gradient"}`}
                            onClick={handleAddButtonClick}
                        >
                            {addButton}
                        </div>
                        <div className={`${purchasePermitOpen ? "fcc" : "hidden"} ${saveButtonText === "Saving..." && "opacity-50 pointer-events-none"} `} onClick={handleSaveNewVehicle}>
                            <div className={`fcc rounded-md shadow-md yellow-gradient w-fit h-fit py-1 px-4 font-bold `}>
                                {saveButtonText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position='bottom' richColors />
        </div >
    );
}

const vehicleTypes = [
    "",
    "Sedan",
    "SUV",
    "Truck",
    "Van",
    "Hatchback",
    "Coupe",
    "Motorcycle",
    "Convertible",
    "Wagon",
    "RV",
    "Bus",
]

const vehicleColors = [
    "",
    "White",
    "Black",
    "Silver",
    "Gray",
    "Red",
    "Orange",
    "Yellow",
    "Green",
    "Blue",
    "Purple",
    "Pink",
    "Brown",
    "Multi-Color",
]

const carBrands = [
    "",
    "Abarth",
    "Alfa Romeo",
    "Aston Martin",
    "Audi",
    "Bentley",
    "BMW",
    "Bugatti",
    "Cadillac",
    "Chevrolet",
    "Chrysler",
    "Citroën",
    "Dacia",
    "Daewoo",
    "Daihatsu",
    "Dodge",
    "Donkervoort",
    "DS",
    "Ferrari",
    "Fiat",
    "Fisker",
    "Ford",
    "Honda",
    "Hummer",
    "Hyundai",
    "Infiniti",
    "Iveco",
    "Jaguar",
    "Jeep",
    "Kia",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Landwind",
    "Lexus",
    "Lotus",
    "Maserati",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mercedes-Benz",
    "MG",
    "Mini",
    "Mitsubishi",
    "Morgan",
    "Nissan",
    "Opel",
    "Peugeot",
    "Porsche",
    "Renault",
    "Rolls-Royce",
    "Rover",
    "Saab",
    "Seat",
    "Skoda",
    "Smart",
    "SsangYong",
    "Subaru",
    "Suzuki",
    "Tesla",
    "Toyota",
    "Volkswagen",
    "Volvo"
]