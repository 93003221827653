import { CardElement, AddressElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';

const PaymentForm = ({ type, amount, dbFunction, permitDescription, permitUni, permitType, onBack }) => {
    const [message, setMessage] = useState('');
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const title = `${type.charAt(0).toUpperCase() + type.slice(1)} ${type === "citation" ? "Amount" : "Price"}:`;
    let price = 0;

    if (typeof amount === 'string') {
        price = amount.includes('.') ? parseFloat(amount.replace('.', '')) : parseFloat(amount) * 100;
    } else {
        price = amount * 100;
    }

    const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    }).format(price / 100);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        if (!stripe || !elements) {
            return;
        }

        const addressElement = elements.getElement(AddressElement);
        const { complete, value } = await addressElement.getValue();

        let addressDetails = {};
        if (complete) {
            // Extract the name along with address details
            addressDetails = {
                ...value.address,
                name: value.name || '', // Ensure the name is included
            };
        } else {
            setMessage("Please complete the address form.");
            setSubmitting(false);
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (!error) {
            const { id } = paymentMethod;

            try {
                const response = await fetch('https://ae967eoek9.execute-api.us-east-1.amazonaws.com/default/stripePayment', { //TODO: Move this to the fetcher file
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: "processPayment", id, amount: price, address: addressDetails, description: `${permitDescription} Permit at ${permitUni}`
                    }),
                });

                if (response.ok) {
                    console.log(response)
                    const data = await response.json();
                    const confirmationNumber = data.confirmationNumber;
                    setMessage(data.message);
                    setConfirmationNumber(confirmationNumber);

                    // Write to citation database
                    if (type === "citation") {
                        dbFunction(confirmationNumber)
                    } else {
                        const objBuild = {
                            confirmationNumber: confirmationNumber,
                            description: permitDescription,
                            price: amount,
                            organization: permitUni,
                            type: permitType,
                            purchaseDate: new Date().toISOString(),
                            expirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
                        }
                        console.log(objBuild)

                        dbFunction(objBuild);
                    }
                } else {
                    const data = await response.json();
                    setMessage(`Payment failed: ${data.message}`);
                }
            } catch (error) {
                setMessage(`Payment failed: ${error.message}`);
            }
        } else {
            setMessage(error.message);
        }
        setSubmitting(false);
        setSuccess(true);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className={`flex-col gap-4 w-full px-4 ${success ? "hidden" : "flex"}`}>
                <h2 className='w-full text-center text-xl'>{title} {formattedAmount}</h2>
                <AddressElement options={{ mode: 'billing' }} />
                <CardElement id='card-element' className='h-11 border-2 border-gray-200 dark:bg-white rounded-md w-full p-3' />
                <div className="frc gap-6">
                    <button type="button" disabled={submitting} onClick={() => onBack()} className={`w-20 h-8 self-center rounded-md light-gray-gradient shadow-md dark:text-spotGray`}>
                        Close
                    </button>
                    <button type="submit" disabled={submitting} className={`w-fit h-fit px-4 py-1 ${submitting && "opacity-50 pointer-events-none"} self-center rounded-md yellow-gradient shadow-md dark:text-spotGray`}>
                        {submitting ? 'Processing...' : 'Submit'}
                    </button>
                </div>
            </form>
            {message && (
                <div className='flex flex-col justify-center items-center'>
                    <h2 className='text-xl dark:text-gray-300'>{success ? 'Success!' : 'Error'}</h2>
                    {success && <><h2 className='dark:text-gray-300'>Confirmation Number</h2><p className='dark:text-gray-300'>{confirmationNumber}</p></>}
                </div>
            )}
        </div>
    );
}

export default PaymentForm;