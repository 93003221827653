import React from 'react';
import { PermitCard } from './PermitCard.js';
import { PermitBrowser } from './PermitBrowser.js';
import "../../../styles.css";
import './profile.css';
import { BackArrow } from '../../utility/BackArrow.js';

export const ExpiredPermits = () => {
    // Get user info
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const permitData = userData.permits.filter(permit => new Date(permit.expirationDate) < new Date());

    return (
        <div className='fcc p-6'>
            <BackArrow path="/profile" />
            <div className="w-full flex flex-col gap-6 mt-12 pb-32 items-center">
                <div className={`flex flex-col gap-4 w-full`}>
                    {permitData.map((permit, index) => (
                        <div key={`permit-${index}`}>
                            {PermitCard({ permitData: permit })}
                        </div>)
                    )}
                </div>
                <PermitBrowser />
            </div>
        </div >
    );
}