import React, { useState } from 'react';
import { logout } from './logout';
import { Button } from './Button';

const ErrorScreen = () => {
    const [reloadButton, setReloadButton] = useState("Reload");
    const [logOut, setLogOut] = useState("Log Out");

    const handleReload = () => {
        setReloadButton("Reloading...");
        window.location.href = "/";
    };

    const handleLogOut = () => {
        setLogOut("Logging Out...");
        logout();
        window.location.href = "/login";
    };

    return (
        <div className="flex flex-col gap-6 items-center justify-center h-screen">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-32 h-32 text-spotYellow animate-bounce"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z"
                />
            </svg>
            <div className="fcc">
                <h1 className="text-3xl font-bold text-spotGray">Oops!</h1>
                <p className="text-lg text-gray-500">Something went wrong.</p>
            </div>
            <div className="frc gap-2">
                <Button text={reloadButton} onClick={handleReload} color={"gray"} />
                <Button text={logOut} onClick={handleLogOut} color={"yellow"} />
            </div>
        </div>
    );
};

export default ErrorScreen;