import React from "react";

export const PermitCard = ({ permitData }) => {
    const formattedDate = new Date(permitData.expirationDate).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    const activeVehiclesString = permitData.vehicles.join(", ");

    return (
        <div className="border border-gray-100 shadow-md text-spotGray dark:text-gray-300 rounded-md p-4 flex flex-col gap-2">
            <div className="w-full flex flex-row gap-2 text-spotGray dark:text-gray-300">
                <label htmlFor="organization" className='whitespace-nowrap'>Organization: </label>
                <div className="text-sm font-normal mt-[2px]">{permitData.organization}</div>
            </div>
            <div className="w-full flex flex-row gap-2">
                <label htmlFor="organization" className='whitespace-nowrap'>Permit Type: </label>
                <div className="text-sm font-normal mt-[2px]">{permitData.type}</div>
            </div>
            <div className="w-full flex flex-row gap-2">
                <label htmlFor="organization" className='whitespace-nowrap'>Description: </label>
                <div className="text-sm font-normal mt-[2px]">{permitData.description}</div>
            </div>
            <div className="w-full flex flex-row gap-2">
                <label htmlFor="organization" className='whitespace-nowrap'>Price: </label>
                <div className="text-sm font-normal mt-[2px]">{permitData.price}</div>
            </div>
            <div className="w-full flex flex-row gap-2">
                <label htmlFor="licensePlate" className='whitespace-nowrap'>Active Plates: </label>
                <div className="text-sm font-mono mt-[2px]">{activeVehiclesString}</div>
            </div>
            <div className="w-full flex flex-row gap-2">
                <label htmlFor="organization" className='whitespace-nowrap'>Expiration Date: </label>
                <div className="text-sm font-normal mt-[2px]">{formattedDate}</div>
            </div>
        </div>
    )
}