import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../utility/UserPool";
import "./login.css";
import { Toaster, toast } from "sonner";

export const SignUp = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [boxPosition, setBoxPosition] = useState("");
    const [coverPosition, setCoverPosition] = useState("");

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        const attributeList = [
            new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
            new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
            new CognitoUserAttribute({ Name: "email", Value: email }),
        ];

        UserPool.signUp(email, password, attributeList, null, (err, data) => {
            if (err) {
                console.error("Error signing up:", err);
                setErrorMessage(err.message);
                return;
            }

            const userObj = {
                email: email,
                firstName: firstName,
                lastName: lastName,
            };

            sessionStorage.setItem("userObj", JSON.stringify(userObj));

            console.log("User registered successfully:", data);
            toast.success("Account created successfully!");

            navigate("/confirm-email", { state: { email } });
        });
    };

    // Main register tile
    const register = () => {
        return (
            <div className="fcc bg-white w-[85%] z-50 rounded-2xl p-4 text-spotGray">
                <h1 className="text-spotGray text-4xl font-bold pb-2">Sign Up</h1>
                <p className="mb-4 text-center">Create an account to use Spot Parking</p>
                <div className="w-full">
                    <form onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center">
                        <label className="w-full">
                            <div className="font-bold text-xl">
                                First Name
                            </div>
                            <input
                                type="text"
                                value={firstName}
                                className="w-full h-[40px] border border-gray-400 rounded-lg pl-2 text-spotGray bg-white mb-4"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </label>
                        <label className="w-full">
                            <div className="font-bold text-xl">
                                Last Name
                            </div>
                            <input
                                type="text"
                                value={lastName}
                                className="w-full h-[40px] border border-gray-400 rounded-lg pl-2 text-spotGray bg-white mb-4"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </label>
                        <label className="w-full">
                            <div className="font-bold text-xl">
                                Email
                            </div>
                            <input
                                type="email"
                                value={email}
                                className="w-full h-[40px] border border-gray-400 rounded-lg pl-2 text-spotGray bg-white mb-4"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <label className="w-full">
                            <div className="font-bold text-xl">
                                Password
                            </div>
                            <input
                                type="password"
                                value={password}
                                className="w-full h-[40px] border border-gray-400 rounded-lg pl-2 text-spotGray bg-white mb-4"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p className="text-red-500 fond-bold mb-2">{errorMessage}</p> : <div className="mb-1"><br /></div>}
                        <button className="w-40 h-12 yellow-gradient rounded-2xl font-bold text-2xl transition-all duration-100 hover:bg-gradient-to-r hover:from-white hover:to-white hover:text-spotYellow hover:border-2 hover:border-spotYellow hover:shadow-lg" type="submit">Sign Up</button>
                        <Link to={"/login"} className="mt-4 underline font-semibold text-spotGray">
                            Back to login
                        </Link>
                    </form>
                </div>
            </div>
        );
    };

    // Fancy squares
    const square = (width, height, x, y, rotation, time) => {
        const newY = boxPosition ? boxPosition : y;

        return (
            <div className="background-square" style={{
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                transform: `rotate(${rotation}deg)`,
                top: `${newY}vh`,
                left: `${x}vw`,
                transition: `all ${time}s ease-in-out`,
            }}></div>
        );
    };

    // Coverer for screen animation
    const coverer = () => {
        const newY = coverPosition ? coverPosition : "100vh";
        return (<div className="coverer" style={{ top: newY }}></div>);
    };

    document.body.style.overflow = 'hidden';

    return (
        <div className="fcc bg-gradient-to-t from-spotYellow to-spotGray w-screen h-screen fixed top-0 left-0 bottom-0 right-0 overflow-hidden">
            {coverer()}
            <div className="fcc w-screen">
                {register()}
            </div>
            <div className="square-div" style={{ overflow: "hidden" }}>
                {square(70, 50, 20, -25, 40, 1.0)}
                {square(70, 70, 0, 80, -20, 2.0)}
                {square(70, 70, 90, 40, -20, 1.5)}
            </div>
        </div>
    );
};