import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../utility/UserPool";
import Cookies from "js-cookie";
import "./login.css";
import { logout } from "../utility/logout";

export const Login = () => {
    // Initialize state variables
    const [loginButton, setLoginButton] = useState("Login");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [boxPosition, setBoxPosition] = useState("");
    const [coverPosition, setCoverPosition] = useState("");
    const jsCookie = Cookies.get("accessToken");

    // If the user is already authenticated, redirect them to the dashboard
    if (jsCookie !== undefined) {
        return <Navigate to="/" />;
    } else {
        const allowedPaths = ["/login", "/contact-us", "/reset-password"]; // These are the paths that the user is allowed to access without logging in
        if (!allowedPaths.includes(window.location.pathname)) {
            logout();
            window.location.href = "/login";
        }
    }

    const handleSubmit = (event) => {
        try {
            event.preventDefault(); // Prevents default refresh by the browser
        } catch (error) {
            console.log("No event detected");
        }

        setLoginButton("Logging in...");

        if (username === "") {
            setErrorMessage("Please enter an email.");
            return;
        } else if (password === "") {
            setErrorMessage("Please enter a password.");
            return;
        }

        // These two chunks are stressful, maybe we shouldn't touch them
        const tempEmail = username.trim().toLowerCase();
        const user = new CognitoUser({
            Username: tempEmail,
            Pool: UserPool
        });

        const authDetail = new AuthenticationDetails({
            Username: tempEmail,
            Password: password.trim()
        });

        // Authenticate the user
        user.authenticateUser(authDetail, {
            onSuccess: (data) => {
                setBoxPosition("-110");

                const jwt = data.getAccessToken().getJwtToken()
                const cognitoUser = UserPool.getCurrentUser();

                cognitoUser.getSession((err, session) => {
                    if (err) {
                        console.log(err);
                        return;
                    }

                    cognitoUser.getUserAttributes((err, attributes) => {
                        if (err) {
                            console.log(err);
                            return;
                        }

                        const username = cognitoUser.getUsername();
                        localStorage.setItem("username", username);
                        console.log(attributes);
                        for (let i in attributes) {
                            console.log(attributes[i].getName(), attributes[i].getValue());
                            localStorage.setItem(attributes[i].getName(), attributes[i].getValue());
                        }
                    });
                });

                setTimeout(() => {
                    setCoverPosition("-1900vh");
                }, 500);

                setTimeout(() => {
                    localStorage.setItem('jwt', jwt);
                    Cookies.set("accessToken", jwt, { expires: 7 });
                    window.location.href = "/";
                }, 2000);

                setTimeout(() => {
                    document.body.style.backgroundColor = "white";
                }, 2000);

            },
            onFailure: (err) => {
                console.error("Failure:", err);
                setLoginButton("Login");
                setErrorMessage(err.message);
            },
            newPasswordRequired: (data) => {
                window.location.href = "/reset-password";
                console.log("New password required:", data);
            }
        });
    }

    const demoSubmit = () => {
        setLoginButton("Logging in...");
        setUsername("demo@spotparking.app");
        setPassword("spotparking");
        // These two chunks are stressful, maybe we shouldn't touch them
        const user = new CognitoUser({
            Username: "demo@spotparking.app",
            Pool: UserPool
        });

        const authDetail = new AuthenticationDetails({
            Username: "demo@spotparking.app",
            Password: "spotparking"
        });

        // Authenticate the user
        user.authenticateUser(authDetail, {
            onSuccess: (data) => {
                setBoxPosition("-110");

                const jwt = data.getAccessToken().getJwtToken()
                const cognitoUser = UserPool.getCurrentUser();

                cognitoUser.getSession((err, session) => {
                    if (err) {
                        console.log(err);
                        return;
                    }

                    cognitoUser.getUserAttributes((err, attributes) => {
                        if (err) {
                            console.log(err);
                            return;
                        }

                        const username = cognitoUser.getUsername();
                        localStorage.setItem("username", username);
                        for (let i in attributes) {
                            localStorage.setItem(attributes[i].getName(), attributes[i].getValue());
                        }
                    });
                });

                setTimeout(() => {
                    setCoverPosition("-1900vh");
                }, 500);

                setTimeout(() => {
                    localStorage.setItem('jwt', jwt);
                    Cookies.set("accessToken", jwt, { expires: 7 });
                    window.location.href = "/";
                }, 2000);

            },
            onFailure: (err) => {
                console.error("Failure:", err);
                setLoginButton("Login");
                setErrorMessage(err.message);
            },
            newPasswordRequired: (data) => {
                window.location.href = "/reset-password";
                console.log("New password required:", data);
            }
        });
    }

    // Main login tile
    const login = () => {
        return (
            <div className="fcc bg-white w-[85%] z-50 rounded-2xl p-4 text-spotGray">
                <h1 className="text-spotGray text-4xl font-bold pb-4">Login</h1>
                <p className="mb-4 text-center">Log in using credentials provided by the Spot Parking team.</p>
                <div className="w-full fcc">
                    <form onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center">
                        <label className="w-full">
                            <div className="font-bold text-xl">
                                Email
                            </div>
                            <input
                                type="text"
                                value={username}
                                className="w-full h-[40px] border border-gray-400 rounded-lg pl-2 text-spotGray bg-white mb-4"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                        <label className="w-full">
                            <div className="font-bold text-xl">
                                Password
                            </div>
                            <input
                                type="password"
                                value={password}
                                className="w-full h-[40px] border border-gray-400 rounded-lg pl-2 text-spotGray bg-white mb-4"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p className="text-red-500 fond-bold mb-2">{errorMessage}</p> : <div className="mb-1"><br /></div>}
                        <button className="w-40 h-12 yellow-gradient rounded-2xl font-bold text-2xl transition-all duration-100 hover:bg-gradient-to-r hover:from-white hover:to-white hover:text-spotYellow hover:border-2 hover:border-spotYellow hover:shadow-lg" type="submit">{loginButton}</button>
                    </form>
                    <Link to={"/signup"} className="mt-4 underline font-semibold text-spotGray w-full text-center">
                        Sign Up
                    </Link>
                </div>
            </div>
        )
    }

    // Fancy squares yuh
    const square = (width, height, x, y, rotation, time) => {
        const newY = boxPosition ? boxPosition : y; // Helps with animation

        // Inline style used for animation. Other CSS in the css file
        return (
            <div className="background-square" style={{
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                transform: `rotate(${rotation}deg)`,
                top: `${newY}vh`,
                left: `${x}vw`,
                transition: `all ${time}s ease-in-out`,
            }}></div>
        );
    }

    // This is what slides up to cover the screen
    const coverer = () => {
        const newY = coverPosition ? coverPosition : "100vh";
        return (<div className="coverer" style={{ top: newY }}></div>)
    }

    document.body.style.overflow = 'hidden';

    return (
        <div className="fcc bg-gradient-to-t from-spotYellow to-spotGray w-screen h-screen fixed top-0 left-0 bottom-0 right-0 overflow-hidden">
            {coverer()}
            <div className="fcc gap-4">
                {login()}
                <div className="flex flex-row justify-between rounded-2xl h-16 w-[85%] bg-white z-50 shadow-lg">
                    <div className="flex flex-row font-bold text-lg text-center self-center ml-4">
                        <span className=" drop-shadow-lg mr-2">👀</span> Looking for the demo?
                    </div>
                    <div className="flex justify-center items-center bg-spotGray cursor-pointer text-white rounded-2xl w-30 mr-2 h-12 px-2 self-center font-bold text-xl transition-all duration-200 hover:bg-spotYellow hover:text-spotGray hover:shadow-lg" onClick={demoSubmit}>
                        Click Here
                    </div>
                </div>
            </div>
            <div className="square-div" style={{ overflow: "hidden" }}>
                {square(70, 50, 20, -25, 40, 1.0)}
                {square(70, 70, 0, 80, -20, 2.0)}
                {square(70, 70, 90, 40, -20, 1.5)}
            </div>
        </div>
    );
}