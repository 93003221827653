import React, { useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../utility/UserPool';
import "./login.css"

export const ResetPassword = () => {
    // Initialize state variables
    const [username, setUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [boxPosition, setBoxPosition] = useState("");
    const [coverPosition, setCoverPosition] = useState("");

    // This is stressful
    const handleResetPassword = async (event) => {
        event.preventDefault(); // Prevents default refresh by the browser

        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });

        user.authenticateUser(new AuthenticationDetails({
            Username: username,
            Password: oldPassword,
        }), {
            onSuccess: (data) => {
                user.changePassword(oldPassword, newPassword, (error, result) => {
                    if (error) {
                        console.log(error);
                        setErrorMessage(error.message);
                    } else {
                        // Password changed successfully
                        // Clear the error message and reset the input fields
                        setErrorMessage('');
                        setOldPassword('');
                        setNewPassword('');
                        setBoxPosition("-110");

                        // Cover page before redirecting
                        setTimeout(() => {
                            setCoverPosition("-10vh");
                        }, 1000);

                        // Redirect to the dashboard and set access token
                        setTimeout(() => {
                            sessionStorage.setItem("isLoggedIn", true);
                            document.cookie = `accessToken=${data.getAccessToken().getJwtToken()};`;
                            window.location.href = '/dashboard';
                        }, 2000);
                    }
                });
            },
            onFailure: (error) => {
                // Error message
                console.log(error);
                setErrorMessage(error.message);
            },
            newPasswordRequired: () => {
                user.completeNewPasswordChallenge(newPassword, {}, {
                    onSuccess: (data) => {
                        // Password changed successfully
                        // Clear the error message and reset the input fields
                        setErrorMessage('');
                        setOldPassword('');
                        setNewPassword('');
                        setBoxPosition("-110");

                        // Cover page before redirecting
                        setTimeout(() => {
                            setCoverPosition("-10vh");
                        }, 1000);

                        // Redirect to the dashboard and set access token
                        setTimeout(() => {
                            sessionStorage.setItem("isLoggedIn", true);
                            const expirationTime = new Date(Date.now() + data.getAccessToken().getExpiration() * 1000);
                            document.cookie = `accessToken=${data.getAccessToken().getJwtToken()}; expires=${expirationTime.toUTCString()}; path=/;`;
                            window.location.href = '/dashboard';
                        }, 2000);
                    },
                    onFailure: (error) => {
                        console.log(error);
                        setErrorMessage(error.message);
                    }
                });
            },
        });
    };

    // Main tile
    const tile = () => {
        return (
            <div className='login-container' style={{ height: "435px" }}>
                <h1 style={{ color: "#323232", fontSize: "30px", fontWeight: "bold", marginBottom: "-10px", marginTop: "25px" }}>Reset Password</h1>
                <div style={{ width: "75%" }}>
                    <form onSubmit={handleResetPassword}>
                        <label>
                            <br />
                            <div className="login-title">
                                Username
                            </div>
                            <input
                                type="text"
                                value={username}
                                className='login-input'
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div className="login-title">
                                Old Password
                            </div>
                            <input
                                type="password"
                                value={oldPassword}
                                className='login-input'
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div className="login-title">
                                New Password
                            </div>
                            <input
                                type="password"
                                value={newPassword}
                                className='login-input'
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p style={{ color: "red", fontSize: "16px", marginTop: "5px", marginBottom: "-15px" }}>{errorMessage}</p> : null}
                        <br />
                        <br />
                        <button className="login-button" type="submit" style={{ marginLeft: "22vw" }}>Reset</button>
                    </form>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontSize: "12px",
                        color: "#323232",
                        marginBottom: "-20px",
                    }}>
                        <a style={{ textDecoration: "underline", marginTop: "0px", color: "#323232", textAlign: "center" }} href="/login">Go Back</a>
                    </div>
                </div>
            </div>
        )
    }

    // Fancy squares yuh
    const square = (width, height, x, y, rotation) => {
        const newY = boxPosition ? boxPosition : y; // Assist with animation

        // Inline used for animation. It's not worth pushing it to CSS
        return (
            <div style={{
                position: "absolute",
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                backgroundColor: "#D9D9D9",
                transform: `rotate(${rotation}deg)`,
                borderRadius: "45px",
                opacity: "0.2",
                boxShadow: "0 0px 36px 18px rgba(0, 0, 0, 1.45)",
                top: `${newY}vh`,
                left: `${x}vw`,
                zIndex: "0",
                transition: "all 1s ease-in-out",
            }}></div>
        );
    }

    // White gradient
    const coverer = () => {
        const newY = coverPosition ? coverPosition : "100vh"; // Assist with animation
        return (<div className='reset-password-gradient' style={{ top: newY }}></div>)
    }

    return (
        <div className='reset-password-main-container'>
            {coverer()}
            {tile()}
            <div className='reset-password-square-div'>
                {square(70, 50, 20, -25, 40)}
                {square(70, 70, 0, 80, -20)}
                {square(70, 70, 90, 40, -20)}
            </div>
        </div>
    );
}