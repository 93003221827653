import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCar } from "react-icons/fa";
import { PiClockClockwiseFill } from "react-icons/pi";
import { RxCaretRight } from "react-icons/rx";
import { BsFilePerson } from "react-icons/bs";
import { Toaster, toast } from 'sonner';
import { activePermitSVG } from "../../../assets/activePermits.js"
import { faqIcon } from "../../../assets/faqIcon.js"
import { helpCenterIcon } from '../../../assets/helpCenterIcon.js';
import ErrorBoundary from '../../utility/ErrorBoundary.js';
import { fetchAllData } from "../../utility/fetcher.js";
import "../../../styles.css";
import './profile.css';

const Profile = () => {
    const [color, setColor] = useState('#ffffff'); // Default color
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const email = localStorage.getItem("email");
    const user = localStorage.getItem("username");
    const capitalizedUser = user.charAt(0).toUpperCase() + user.slice(1);
    sessionStorage.setItem("user", capitalizedUser);

    var mt = "mt-16"
    const isNotIphone = !navigator.userAgent.match(/iPhone/i);
    if (isNotIphone) {
        mt = "mt-0"
    }

    const storeData = async () => {
        var currentTime = new Date().getTime();
        var secondTime = 0;
        try {
            const path = { "userData": `/general/spotUsers?email=${email}` }
            const data = await fetchAllData(path);
            setUserData(data.userData);
            sessionStorage.setItem("userData", JSON.stringify(data.userData));
            setLoading(false);
            secondTime = new Date().getTime();
        } catch (error) {
            console.error("Error storing data:", error);
        }

        return `Time to load ${((secondTime - currentTime) / 1000).toFixed(3)} seconds`;
    };

    const settingItem = (icon, label, link) => {
        if (label === "Help Center") {
            return (
                <a href="https://spotparking.app/contact" target="_blank" rel="noreferrer" className={`flex flex-row w-[90%] p-2 pl-6 items-center justify-between ${loading ? "pointer-events-none text-gray-300" : "text-spotGray"}`}>
                    <div className="frc">
                        <div className="pr-4">{icon}</div>
                        <div className="text-lg text-left dark:text-gray-400">{label}</div>
                    </div>
                    <RxCaretRight className={`w-8 h-8 dark:text-gray-500 ${loading ? "pointer-events-none text-gray-300" : "text-spotGray"}`} />
                </a>
            )
        } else {
            return (
                <Link to={link} className={`flex flex-row w-[90%] p-2 pl-6 items-center justify-between ${loading ? "pointer-events-none text-gray-300" : "text-spotGray"}`}>
                    <div className="frc">
                        <div className="pr-4">{icon}</div>
                        <div className="text-lg text-left dark:text-gray-400">{label}</div>
                    </div>
                    <RxCaretRight className={`w-8 h-8 dark:text-gray-500 ${loading ? "pointer-events-none text-gray-300" : "text-spotGray"}`} />
                </Link>
            )
        }
    }

    // Start by fetching data
    useEffect(() => {
        localStorage.getItem("fName") !== null && setLoading(false);

        storeData();

        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleDarkModeChange = (e) => {
            if (e.matches) {
                setColor('#d4d7dc'); // Dark mode color
            } else {
                setColor('#323232'); // Light mode color
            }
        };

        // Set initial color based on current theme
        if (darkModeMediaQuery.matches) {
            setColor('#d4d7dc'); // Dark mode color
        } else {
            setColor('#323232'); // Light mode color
        }

        // Add event listener
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

        // Clean up event listener
        return () => {
            darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
    }, []);

    let fName = localStorage.getItem("fName");
    let lName = localStorage.getItem("lName");

    if (userData && fName === null) {
        const personalInfo = userData.settings.personalInfo;
        fName = personalInfo.firstName;
        lName = personalInfo.lastName;
        localStorage.setItem("fName", fName);
        localStorage.setItem("lName", lName);
    }

    return (
        <div>
            <div className="bg-spotGray w-screen h-screen">
                <div className="flex flex-col gap-4 fixed bg-spotGray dark:bg-[#222222] w-screen h-screen">
                    <h1 className='font-bold text-4xl text-white dark:text-gray-400 pt-8 pl-8'>Profile</h1>
                    <div className="flex flex-col">
                        <h2 className='font-medium text-xl text-white dark:text-gray-400 pl-8'>{fName} {lName}</h2>
                        <h3 className='font-normal text-md text-[#C7C7C7] dark:text-gray-500 pl-8'>{email}</h3>
                    </div>
                </div>
                <ErrorBoundary>
                    <div id='card' className="bg-white dark:bg-spotGray absolute bottom-0 h-[82%] w-screen rounded-t-[50px] pt-4 flex flex-col gap-4 items-center overflow-x-clip z-40">
                        <div className="flex flex-col mt-2 pt-4 pl-6">
                            <h1 className='font-medium text-lg text-[#5C5C5C] dark:text-gray-400 pl-4 pb-2 text-left w-screen'>Account Settings</h1>
                            {settingItem(<BsFilePerson className='w-8 h-8 text-spotGray dark:text-gray-400' />, "Personal Information", "/personal-information")}
                            {/* {settingItem(<MdOutlinePayment className='w-8 h-8 text-spotGray dark:text-gray-400' />, "Payment", "/payment-information")} */}
                            {settingItem(<FaCar className='w-8 h-8 text-spotGray dark:text-gray-400' />, "Vehicles", "/vehicles")}
                        </div>
                        <div className="flex flex-col mt-2 pt-6 pl-6">
                            <h1 className='font-medium text-lg text-[#5C5C5C] dark:text-gray-400 pl-4 pb-2 text-left w-screen'>Permits</h1>
                            {settingItem(activePermitSVG(32, 32, color), "Active", "/active-permits")}
                            {settingItem(<PiClockClockwiseFill className='w-8 h-8 text-spotGray dark:text-gray-400 scale-x-[-1]' />, "Expired", "/expired-permits")}
                        </div>
                        <div className="flex flex-col mt-2 pt-6 pl-6">
                            <h1 className='font-medium text-lg text-[#5C5C5C] dark:text-gray-400 pl-4 pb-2 text-left w-screen'>Other</h1>
                            {settingItem(faqIcon(32, 32, color), "FAQ", "/faq")}
                            {settingItem(helpCenterIcon(30, 30, color), "Spot Help Center", "/help-center")}
                            {/* {settingItem(helpCenterIcon(30, 30, color), "Organization Info", "/help-center")} */}
                        </div>
                    </div>
                </ErrorBoundary>
                <div id='white-cover' className="bg-white dark:bg-spotGray fixed top-[65%] h-[45%] w-screen"></div>
            </div>
            <Toaster richColors position='top' />
        </div>
    );
};

export default Profile;