import React, { useEffect, useState } from "react";
import PaymentForm from "./citations/PaymentForm";
import ApppealForm from "./citations/AppealForm";
import { fetchAllData, postData } from "../utility/fetcher";
import { Popup } from "../utility/Popup";
import navLogo from "../../assets/navLogo.png";
import { IoIosDownload } from "react-icons/io";

export const VisitorCitation = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const [action, setAction] = useState(null);
    const [message, setMessage] = useState('');
    const [currentCitation, setCurrentCitation] = useState(null);
    const [allUserCitations, setAllUserCitations] = useState(null);

    const getCitation = async () => {
        const path = { "userData": `/general/spotUsers` };
        const response = await fetchAllData(path);
        const allUserData = response.userData;

        let realCitation
        for (let i in allUserData) {
            for (let j in allUserData[i].citations) {
                if (allUserData[i].citations[j].citationID === queryParams.get("citationID")) {
                    realCitation = allUserData[i].citations[j];
                    break;
                }
            }
        }

        realCitation = realCitation === undefined ? "n/a" : realCitation;
        setCurrentCitation(realCitation);
    }

    const individualParameter = (title, data) => {
        const amount = currentCitation.fee;

        if (title === "Fee") {
            const formattedFee = amount.includes("$") ? amount : `$${parseFloat(amount).toFixed(2)}`;
            data = formattedFee;
        }

        return (
            <div className="frc gap-1">
                <p className="text-spotGray dark:text-gray-300 font-bold font-xl">{title}: </p>
                <p className="text-spotGray dark:text-gray-300 text-sm text-left">{data}</p>
            </div>
        );
    }

    const saveToCitationDatabase = async (confirmationNumber) => {
        const path = `/general/spotUsers?${userData.email}`;
        currentCitation.appeals[0] = {
            "appealDate": currentCitation.appeals[0].appealDate,
            "reason": currentCitation.appeals[0].reason,
            "replyDate": new Date().toISOString(),
            "replyNotes": "Paid",
            "requestNotes": currentCitation.appeals[0].requestNotes,
            "reviewedBy": "N/A",
            "status": "Paid"
        }

        const citationBuild = {
            "appeals": currentCitation.appeals,
            "citationID": currentCitation.citationID,
            "fee": amount,
            "location": currentCitation.location,
            "lot": currentCitation.lot,
            "paid": true,
            "spot": currentCitation.spot,
            "timeWritten": currentCitation.timeWritten,
            "type": currentCitation.type,
            "confirmationNumber": confirmationNumber,
            "licensePlate": currentCitation.licensePlate,
            "datePaid": new Date().toISOString(),
        }

        let newAllCitations = [];
        if (allCitations) {
            const filteredCitations = allCitations.filter(citation => citation.citationID !== currentCitation.citationID);
            newAllCitations = [...filteredCitations, citationBuild];
            sessionStorage.setItem("allCitations", JSON.stringify(newAllCitations));
        }

        userData.citations = newAllCitations;

        const response = await postData(path, userData);
        console.log(response.message);
    };

    const saveToAppealDatabase = async (citationData) => {
        const appealBuild = {
            "appealDate": new Date().toISOString(),
            "reason": citationData.reason,
            "replyDate": "",
            "replyNotes": "",
            "requestNotes": citationData.additionalNotes,
            "reviewedBy": "",
            "status": "pending",
        }

        userData.citations.forEach(citation => {
            if (citation.citationID === currentCitation.citationID) {
                console.log(citation)
                citation.appeals.push(appealBuild);
            }
        });

        try {
            const path = `/general/spotUsers?${userData.email}`;
            (async () => {
                const response = await postData(path, userData);
                console.log(response.message);
                setAction(null)
                setMessage('Appeal submitted successfully!');
            })();
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const handleActionClick = (action) => {
        setAction(action);
    }

    const handleBackClick = () => {
        setAction(null);
    }

    useEffect(() => {
        getCitation();
    }, []);

    // useEffect(() => {
    //     const appeals = currentCitation.appeals;
    //     let status = "";
    //     if (appeals.length === 0) {
    //         status = "No Appeals Pending";
    //     } else if (currentCitation.paid === true) {
    //         status = "PAID";
    //     } else {
    //         for (let i in appeals) {
    //             if (appeals[i].status === "pending") {
    //                 status = "Appeal Pending"
    //                 break;
    //             } else if (appeals[i].status === "Denied") {
    //                 status = "Appeal Denied"
    //                 break;
    //             } else if (appeals[i].status === "Approved") {
    //                 status = "Appeal Approved"
    //                 break;
    //             }
    //         }
    //     }
    // }, [currentCitation]);

    const popupContent = (
        <div className="fcc gap-2 font-rubik text-center">
            <h1 className="text-2xl text-spotGray dark:text-gray-300">Save your payment info</h1>
            <div className="frc gap-4">
                <p className="text-md w-full text-spotGray dark:text-gray-300">by downloading the Spot Parking app.</p>
                <a href="https://nav.spotparking.app/" target="_blank" rel="noreferrer" className="relative">
                    <img src={navLogo} alt="Spot Parking Logo" className="w-20 h-auto drop-shadow-lg" />
                    <IoIosDownload className="text-3xl text-gray-500 dark:text-gray-300 absolute -bottom-2 -right-2" />
                </a>
            </div>
        </div>
    );

    return (
        <div className="fcc pb-24">
            {currentCitation !== null ?
                currentCitation === "n/a" ?
                    <div className="w-screen h-screen fcc gap-4 text-spotGray dark:text-gray font-rubik">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-32 h-32 text-spotYellow animate-bounce"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z"
                            />
                        </svg>
                        <div className="fcc">
                            <div className="text-lg">No citation found</div>
                            <div className="text-xs">Please try again later.</div>
                        </div>
                    </div> :
                    <div className="fcc w-screen p-4 gap-4 pb-8">
                        <h1 className="text-3xl text-spotGray dark:text-gray-300">Citation Info</h1>
                        <div className="flex flex-col justify-start items-start p-4 rounded-lg shadow-md w-full h-fit py-4 border border-gray-100 dark:border-gray-600 dark:text-gray-300">
                            <h2 className="text-2xl text-spotGray dark:text-gray-300 font-medium w-full text-center pb-2">Citation #{currentCitation.citationID}</h2>
                            {individualParameter("Location", currentCitation.location)}
                            {individualParameter("License Plate", currentCitation.licensePlate)}
                            <div className="frc gap-4">
                                {individualParameter("Spot", `${currentCitation.lot}-${currentCitation.spot}`)}
                                {individualParameter("Fee", currentCitation.fee)}
                            </div>
                            {individualParameter("Time Written", new Date(currentCitation.timeWritten).toLocaleString())}
                            {individualParameter("Violation Type", currentCitation.type)}
                            {individualParameter("Status", status)}
                            {currentCitation.Paid ? individualParameter("Confirmation Number", currentCitation.confirmationNumber) : null}
                        </div>
                        {currentCitation.paid ? null :
                            <div className={`w-full h-fit py-4  ${action !== null ? "fcc border border-gray-100 rounded-lg shadow-md" : "flex flex-row justify-center transition-all duration-500 ease-in-out"}`}>
                                {message !== '' ? <p className="text-center text-spotGray dark:text-gray-300">{message}</p> : status === "Appeal Approved" ? null :
                                    <div className={`${action !== null || action === "done" ? "hidden" : "w-full gap-6 frc"} transition-all duration-500 ease-in-out`}>
                                        {status === "No Appeals Pending" &&
                                            <button type="submit" onClick={() => handleActionClick("appeal")} className={`w-20 h-8 self-center rounded-md light-gray-gradient rubik-medium shadow-md dark:text-spotGray`}>
                                                Appeal
                                            </button>
                                        }
                                        <button type="submit" onClick={() => handleActionClick("pay")} className={`w-20 h-8 self-center rounded-md yellow-gradient rubik-medium shadow-md dark:text-spotGray`}>
                                            Pay
                                        </button>
                                    </div>
                                }
                                {action === null ? null : action === "pay" ? <PaymentForm amount={currentCitation.fee} type={"citation"} dbFunction={saveToCitationDatabase} onBack={handleBackClick} description={`Citation #${currentCitation.CitationID}`} /> : <ApppealForm amount={currentCitation.fee} dbFunction={saveToAppealDatabase} onBack={handleBackClick} description={`Citation #${currentCitation.citationID}`} />}
                            </div>
                        }
                    </div>
                : null}
            <Popup content={popupContent} closeButton={"I want to stay on the web"} />
        </div>
    )
}