import React, { useState } from 'react';
import { Toaster, toast } from 'sonner';
import { BackArrow } from '../../utility/BackArrow.js';
import { useNavigate } from 'react-router-dom';
import '../../../styles.css';
import './profile.css';
import { postData } from '../../utility/fetcher.js';

function validateVin(vin) {
    const re = /^[A-HJ-NPR-Z\d]{8}[\dX][A-HJ-NPR-Z\d]{2}\d{6}$/i;
    const result = re.test(vin);
    console.log(result)
    return result;
}

export const SingleVehicle = () => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const ogVehicle = JSON.parse(sessionStorage.getItem("vehicleData"))
    const [editedData, setEditedData] = useState(ogVehicle);
    const [isValid, setIsValid] = useState(true);
    const [deleteButtonText, setDeleteButtonText] = useState("Delete");
    const [saveButtonText, setSaveButtonText] = useState("Save");
    const navigate = useNavigate();

    const handleEdit = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleSave = async () => {
        if (editedData.make === "" || editedData.model === "" || editedData.type === "" || editedData.color === "" || editedData.licensePlate === "" || editedData.vin === "") {
            toast.error("Please fill out all fields.");
            return;
        }

        setSaveButtonText("Saving...");

        const objBuild = {
            make: editedData.make,
            model: editedData.model.toString().trim(),
            type: editedData.type,
            color: editedData.color,
            licensePlate: editedData.licensePlate.toString().trim(),
            vin: editedData.vin.toString().trim()
        };

        const vehicles = userData.settings.vehicles;
        const filteredVehicles = vehicles.filter(vehicle => vehicle.licensePlate !== ogVehicle.licensePlate);
        const updatedVehicles = [...filteredVehicles, objBuild];
        userData.settings.vehicles = updatedVehicles;

        try {
            console.log("Data to save:", userData);
            const path = "/general/spotUsers";
            const response = await postData(path, userData);
            console.log(response.message);
            window.location.href = "/vehicles";
            sessionStorage.setItem("userData", JSON.stringify(userData));
            setSaveButtonText("Saved");
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Error saving data.");
        }
    };

    const handleDelete = async () => {
        const allPermits = userData.permits;
        const permitsWithThisPlate = allPermits.filter(permit => permit.vehicles.includes(ogVehicle.licensePlate));

        if (permitsWithThisPlate.length > 0) {
            toast.error("Unable to delete vehicle that is associated with an active permit.");
        } else {
            setDeleteButtonText("Deleting...");
            const vehicles = userData.settings.vehicles;
            const filteredVehicles = vehicles.filter(vehicle => vehicle.licensePlate !== ogVehicle.licensePlate);
            userData.settings.vehicles = filteredVehicles;

            try {
                console.log("Data to delete:", userData);
                const path = "/general/spotUsers";
                const response = await postData(path, userData);
                sessionStorage.setItem("userData", JSON.stringify(userData));
                console.log(response.message);
            } catch (error) {
                console.error("Error deleting vehicle:", error);
                toast.error("Error deleting vehicle.");
            }

            setDeleteButtonText("Delete");
            navigate("/vehicles");
        }
    };

    return (
        <div className='fcc p-6'>
            <BackArrow path="/vehicles" />
            <div className="mt-8 fcc">
                <h1 className='text-2xl font-bold dark:text-gray-300 text-left w-screen p-6'>Edit Vehicle</h1>
                <form className='fcc gap-4 w-screen px-12'>
                    <div className='flex flex-col gap-4 p-2 text-spotGray dark:text-gray-300'>
                        <div className='frc gap-2'>
                            <label htmlFor="make">Make:</label>
                            <select
                                name='make'
                                value={editedData["make"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 rounded-md">
                                {carBrands.map((brand, index) => (
                                    <option key={`brand-${index}`} value={brand}>{brand}</option>
                                ))}
                            </select>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="model">Model: </label>
                            <input
                                name='model'
                                value={editedData["model"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 rounded-md">
                            </input>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="type">Type: </label>
                            <select
                                name='type'
                                value={editedData["type"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 rounded-md">
                                {vehicleTypes.map((type, index) => (
                                    <option key={`type-${index}`} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="color">Color: </label>
                            <select
                                name='color'
                                value={editedData["color"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 border border-gray-200 rounded-md">
                                {vehicleColors.map((color, index) => (
                                    <option key={`color-${index}`} value={color}>{color}</option>
                                ))}
                            </select>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="licensePlate" className='min-w-fit'>License Plate: </label>
                            <input
                                name='licensePlate'
                                value={editedData["licensePlate"]}
                                onChange={handleEdit}
                                className="w-full inline-block pl-1 text-sm font-normal h-6 font-mono border border-gray-200 rounded-md">
                            </input>
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <label htmlFor="vin" className='min-w-fit'>VIN #: </label>
                            <input
                                name='vin'
                                value={editedData["vin"]}
                                onChange={handleEdit}
                                onBlur={() => setIsValid(validateVin(editedData["vin"]))}
                                className="w-full inline-block pl-1 text-sm font-mono font-normal h-6 border border-gray-200 rounded-md">
                            </input>
                        </div>
                        <div className="text-xs font-normal text-red-500 w-full text-center">
                            {!isValid && 'Invalid VIN'}
                        </div>
                    </div>
                    <div className="frc gap-2 w-full mt-6">
                        <button
                            type="button"
                            className="w-32 h-8 rounded-lg light-gray-gradient"
                            onClick={handleDelete}
                        >{deleteButtonText}</button>
                        <button
                            type="button"
                            className={`w-32 h-8 rounded-lg yellow-gradient ${saveButtonText === "Saving..." && "opacity-50 pointer-events-none"}`}
                            onClick={handleSave}
                        >{saveButtonText}</button>
                    </div>
                </form>
            </div>
            <Toaster position='top' />
        </div>
    );
};

const carBrands = [
    "", "Abarth", "Alfa Romeo", "Aston Martin", "Audi", "Bentley", "BMW", "Bugatti", "Cadillac", "Chevrolet",
    "Chrysler", "Citroën", "Dacia", "Daewoo", "Daihatsu", "Dodge", "Donkervoort", "DS", "Ferrari", "Fiat",
    "Fisker", "Ford", "Honda", "Hummer", "Hyundai", "Infiniti", "Iveco", "Jaguar", "Jeep", "Kia", "KTM",
    "Lada", "Lamborghini", "Lancia", "Land Rover", "Landwind", "Lexus", "Lotus", "Maserati", "Maybach",
    "Mazda", "McLaren", "Mercedes-Benz", "MG", "Mini", "Mitsubishi", "Morgan", "Nissan", "Opel", "Peugeot",
    "Porsche", "Renault", "Rolls-Royce", "Rover", "Saab", "Seat", "Skoda", "Smart", "SsangYong", "Subaru",
    "Suzuki", "Tesla", "Toyota", "Volkswagen", "Volvo"
];

const vehicleTypes = [
    "",
    "Sedan",
    "SUV",
    "Truck",
    "Van",
    "Hatchback",
    "Coupe",
    "Motorcycle",
    "Convertible",
    "Wagon",
    "RV",
    "Bus",
]

const vehicleColors = [
    "",
    "White",
    "Black",
    "Silver",
    "Gray",
    "Red",
    "Orange",
    "Yellow",
    "Green",
    "Blue",
    "Purple",
    "Pink",
    "Brown",
    "Multi-Color",
]