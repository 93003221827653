import React, { useState, useEffect } from "react";
import PullToRefresh from 'react-pull-to-refresh';
import Skeleton from 'react-loading-skeleton'
import { fetchAllData } from "../../utility/fetcher";
import 'react-loading-skeleton/dist/skeleton.css'

export const Announcements = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const user = "Ryan"; // TODO: Change to authenticated user info
    sessionStorage.setItem("user", user);
    const url = { "announcements": "/general/announcements" }

    const storeData = async () => {
        setLoading(true);
        try {
            const data = await fetchAllData(url);
            console.log(data)
            const announcements = data.announcements;
            setData(announcements);
            sessionStorage.setItem("announcements", JSON.stringify(announcements));
            setLoading(false);
        } catch (error) {
            console.error("Error storing data:", error);
        }
    };

    useEffect(() => {
        storeData();
        // eslint-disable-next-line
    }, []);

    const loader = () => {
        const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        const backgroundColor = isDarkMode ? "#606060" : "#ebebeb";
        const highlightColor = isDarkMode ? "#888888" : "#F5F5F5";

        const loaderItem = () => {
            return (
                <div className="flex flex-col gap-1 justify-center w-[95%] items-start h-24 bg-transparent border border-gray-300 dark:border-gray-600 self-center my-2 p-4 rounded-xl shadow-md text-spotGray text-left">
                    <div className="ml-2">
                        <Skeleton height={20} width={0.4 * window.innerWidth} borderRadius={8} baseColor={backgroundColor} highlightColor={highlightColor} />
                    </div>
                    <div className="ml-2">
                        <Skeleton height={35} width={0.84 * window.innerWidth} borderRadius={8} baseColor={backgroundColor} highlightColor={highlightColor} />
                    </div>
                </div>
            )
        }

        return (
            <div className="flex flex-col gap-2 w-[full] mt-4">
                {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} className="fcc">
                        {loaderItem()}
                    </div>
                ))}
            </div>
        )
    }

    const handleRefresh = async () => {
        setTimeout(async () => {
            await storeData();
        }, 200);
    };

    const currentDate = new Date().toISOString();
    const filteredAnnouncements = data.filter(announcement => announcement.active === true && announcement.expirationDate >= currentDate && announcement.recipients.public === true);

    return (
        <div className="scroll-smooth">
            <PullToRefresh
                onRefresh={handleRefresh}
                pullDownThreshold={80}
                className="h-full"
                resistance={2.5}
            >
                <h1 className="scroll-smooth mt-8 font-bold font-rubik text-spotGray dark:text-gray-300 text-3xl text-center">Announcements</h1>
                {loading ?
                    loader()
                    :
                    filteredAnnouncements.length === 0 ? <div className="flex mt-10 w-screen justify-center items-center text-center font-rubik">No active announcements.</div> :
                        <div className="flex flex-col gap-2 w-[full] mt-4 font-rubik">
                            {filteredAnnouncements.map((announcement, index) => {
                                return (
                                    <div key={index} className="flex flex-col gap-2 justify-start w-[95%] items-start h-fit bg-transparent border border-gray-300 dark:border-gray-600 self-center my-2 rounded-xl shadow-md text-spotGray dark:text-gray-300 text-left p-4">
                                        <h2 className="text-lg text-spotGray dark:text-gray-300 font-semibold">{announcement.title}</h2>
                                        <p className="text-spotGray dark:text-gray-300">{announcement.body}</p>
                                    </div>
                                );
                            })}
                        </div>
                }
            </PullToRefresh >
        </div >
    );
}
