import React from "react";

export const Button = ({ text, onClick, width, height, color }) => {
    const gradient = color === "yellow" ? "yellow-gradient" : "light-gray-gradient";

    return (
        <div className={`fcc ${width} ${height} px-4 py-1 ${gradient} rounded-lg shadow-md font-semibold transition-all duration-200 ease-in-out`} onClick={onClick}>
            {text}
        </div>
    );
}