// import { CognitoUserPool } from "amazon-cognito-identity-js";

// // This info is gotten from the AWS Cognito console
// const poolData ={
//     UserPoolId: "us-east-1_1ElJ27R8A",
//     ClientId: "43o2m5bic43eet9qskt7lgla39"
// }

// const UserPool = new CognitoUserPool(poolData);

// export default UserPool;


import { CognitoUserPool } from "amazon-cognito-identity-js";

// This info is gotten from the AWS Cognito console
const poolData = {
    UserPoolId: "us-east-1_ltGLCzKaG",
    ClientId: "2e69m443tbbqhnlgdfstnv2ksl"
}

const UserPool = new CognitoUserPool(poolData);

export default UserPool;