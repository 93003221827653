import React from "react";
import { FaXmark } from "react-icons/fa6";
import { navArrow } from "../../../assets/navArrow";
import { openMapWithDirections } from "../../utility/openMap";

const ViolationCard = ({ violation, onClose }) => {
    const handleClose = () => {
        onClose();
    };

    const handleOpenDirections = (coords) => {
        // Open directions to a destination from the user's current location
        openMapWithDirections(coords[1], coords[0]); // Destination: Provo, UT
    };

    return (
        <div className="fixed frc gap-2 bottom-32 left-6 rounded-lg shadow-md bg-white/20 backdrop-blur-md px-6 py-8 font-bold text-left text-xl text-spotGray dark:text-gray-100 mt-4">
            <p>Spot: {violation.lot}-{violation.spot}</p>
            <button className="bg-gray-200 dark:bg-gray-600 rounded-full text-lg p-1 text-spotGray dark:text-gray-300 absolute top-2 right-2" onClick={handleClose}>
                <FaXmark />
            </button>
            <div className="fcc border bg-transparent h-full w-12 p-2 rounded-xl shadow-md dark:bg-gray-600" onClick={() => handleOpenDirections(JSON.parse(violation.coordinates)[0])}>
                {navArrow(24, 24)}
            </div>
        </div>
    );
};

export default ViolationCard;
