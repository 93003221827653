// Author: Ryan Hagerty

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Load the Stripe public key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(function (registration) {
            console.log('Service Worker registered with scope: ', registration.scope);
        }, function (err) {
            console.log('Service Worker registration failed: ', err);
        });
    });
}

// Add error handling for development
if (process.env.NODE_ENV === 'development') {
    // Override the default error handler
    window.onerror = function (message, source, lineno, colno, error) {
        console.log("Error captured by window.onerror:", { message, source, lineno, colno, error });
        // Prevent default error handling (e.g., red error overlay)
        return true;
    };

    window.onunhandledrejection = function (event) {
        console.log("Unhandled rejection captured:", event.reason);
        // Prevent default error handling (e.g., red error overlay)
        return true;
    };
}

// Render the application
stripePromise.then((stripe) => {
    root.render(
        <Elements stripe={stripe}>
            <App />
        </Elements>
    );
});