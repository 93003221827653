import React, { useState } from 'react';
import { BackArrow } from '../../utility/BackArrow';

const faqs = [
    {
        question: "What is the purpose of Lot View?",
        answer: `In Lot View, you can see all the parking lots on campus and how many spots are available in each lot. This is a great way to see where you can park!`
    },
    {
        question: "What do the gray spots mean?",
        answer: `In Map View, you can see a live updating map of all the parking spots on campus as well as if they are vacant or occupied. Hopefully this will help you find your spot faster!`
    },
    {
        question: "What if I get a ticket and the information is wrong?",
        answer: `Feel free to select the appeal button on the ticket and fill out the form. This will send the ticket to the university for review.`
    },
    {
        question: "What is the best way to sort Lot View?",
        answer: `You can adjust this on the fly or in the settings. Typically, users sort by the number of available spots but you can also sort by the closest lot to you!`
    },
    {
        question: "What does turning on auto-billing do?",
        answer: `When auto-billing is enabled, you can freely park in any metered spot on any campus `
    }
];

export const FAQ = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handlePress = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const singleFaq = (faq, index) => (
        <div key={index} className={`border border-gray-100 shadow-md rounded-md p-4 ${expandedIndex === index ? "bg-gray-100 dark:bg-gray-700" : "bg-white dark:bg-transparent"}`}>
            <div onClick={() => handlePress(index)}>
                <h1 className='text-spotGray dark:text-gray-300'>{faq.question}</h1>
                <div
                    name={expandedIndex === index ? 'chevron-up' : 'chevron-down'}
                    size={24}
                    color="black"
                />
            </div>
            {expandedIndex === index && (
                <div>
                    <h1 className='text-sm text-spotGray dark:text-gray-300 ml-4'>{faq.answer}</h1>
                </div>
            )}
        </div>
    );

    return (
        <div className="">
            <BackArrow path="/profile" />
            <div className='p-8 pt-16 flex flex-col gap-3 pb-32'>
                <h1 className='font-bold text-xl dark:text-gray-300'>Frequently Asked Questions</h1>
                {faqs.map((faq, index) => (
                    singleFaq(faq, index)
                ))}
            </div>
        </div>
    );
};