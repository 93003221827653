import { useState } from 'react';

const ApppealForm = ({ dbFunction, onBack }) => {
    const [submitting, setSubmitting] = useState(false);
    const [dropdown, setDropdown] = useState('');
    const [notes, setNotes] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        if (dropdown === '') {
            alert("Please select a reason for appeal.");
            setSubmitting(false);
            return;
        }

        const appealBuild = {
            "reason": dropdown,
            "additionalNotes": notes
        }

        await dbFunction(appealBuild);
    };

    const handleNotes = (e) => {
        setNotes(e.target.value);
    }

    const handleDropdownChange = (e) => {
        setDropdown(e.target.value);
    }

    return (
        <form onSubmit={handleSubmit} className={`flex-col gap-4 w-full px-4 flex`}>
            <h2 className='w-full text-center text-xl'>Appeal Request</h2>
            <div className="flex flex-col justify-center items-start">
                <div className="flex flex-row items-center gap-4">
                    <label htmlFor="phone" className="text-spotGray dark:text-gray-300 font-bold font-xl max-w-fit">Reason for Appeal:</label>
                    <select id="phone" name="phone" onChange={handleDropdownChange} className="w-full max-w-48 h-8 border border-gray-300 dark:border-gray-600 dark:bg-transparent rounded-md rubik-normal dark:text-gray-300 overflow-ellipsis">
                        <option value="">Select a reason</option>
                        <option value="Wrong Vehicle">You read my license plate wrong</option>
                        <option value="No Signage">Obstructed or missing signage</option>
                        <option value="Owns a Permit">I have a permit</option>
                        <option value="Incorrect Timing">Metered Parking - Time is incorrect</option>
                    </select>
                </div>
                <div className="flex flex-col items-start gap-1 w-full">
                    <label htmlFor="citation" className="text-spotGray dark:text-gray-300 font-bold font-xl mt-4">Additional Notes</label>
                    <textarea id="citation" name="citation" onChange={handleNotes} className="w-full border flex justify-start items-start border-gray-300 dark:border-gray-600 dark:bg-transparent resize h-48 rounded-md rubik-normal pl-2 pt-1 dark:text-gray-300" />
                </div>
            </div>
            <div className="frc gap-6">
                <button type="submit" disabled={submitting} onSubmit={(e) => e.preventDefault()} onClick={() => onBack()} className={`w-20 h-8 self-center rounded-md light-gray-gradient shadow-md dark:text-spotGray`}>
                    Close
                </button>
                <button type="submit" disabled={submitting} className={`w-fit h-fit px-4 py-1 ${submitting && "opacity-50 pointer-events-none"} self-center rounded-md yellow-gradient shadow-md dark:text-spotGray`}>
                    {submitting ? 'Processing...' : 'Submit'}
                </button>
            </div>
        </form>
    );
}

export default ApppealForm;